








































































































import { computed, defineComponent, PropType, onMounted } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import dayjs from 'dayjs'

import InputLabel from '@/elements/InputLabel.vue'
import OrderListSection from '@/components/UpwardRunning/Orders/OrderListSection.vue'
import ShippingAddressDisplay from '@/components/ShippingAddressDisplay.vue'
import OrderShipper from '@/components/OrderHistory/OrderShippers.vue'
import CostSummary from '@/components/OrderHistory/CostSummary.vue'
import SwitchSlot from '@/elements/SwitchSlot.vue'
import OrderRollup from '@/views/Programs/Orders/league/components/OrderRollup.vue'
import ParticipantOrderGrouped from '@/components/OrderHistory/ParticipantOrderGrouped.vue'

import { xRunningStartupOrder } from '@/GeneratedTypes/xOrder/xRunningStartupOrder'
import { xRunningOrder } from '@/GeneratedTypes/xOrder/xRunningOrder'
import { RunningOrderProductInfo } from '@/GeneratedTypes/ListInfo/RunningOrderProductInfo'
import { OrderInfo } from '@/GeneratedTypes/Order/ListInfo/OrderInfo'
import { SectionNameConversion } from '@/views/Programs/Orders/vues/history/ext/SectionNames'
import store from '@/store'

export default defineComponent({
  name: 'OrderDetail',
  components: {
    OrderListSection,
    InputLabel,
    ShippingAddressDisplay,
    OrderShipper,
    CostSummary,
    SwitchSlot,
    OrderRollup,
    ParticipantOrderGrouped,
  },
  props: {
    order: { type: Object as PropType<xRunningStartupOrder | xRunningOrder>, required: true },
    orderInfo: { type: Object as PropType<OrderInfo>, required: true },
    itemList: { type: Array as PropType<RunningOrderProductInfo[]>, required: true, default: () => [] },
  },
  setup(props) {
    const trackingNumbers = computed(() => props.orderInfo.trackingNumbers?.map((x) => x.trackingNumber))
    const shippingMethods = computed(() => props.order.shippingInfo?.shipMethods?.filter((x) => x.selected))
    const shipDate = computed(() =>
      props.orderInfo.shipDate
        ? dayjs(props.orderInfo.shipDate).format('MMM D, YYYY')
        : 'Order has not shipped'
    )

    const statusList = computed(() =>
      cloneDeep(props.orderInfo.statusList ?? []).sort((a, b) =>
        a.valueOf() == b.valueOf() ? 0 : a.valueOf() > b.valueOf() ? -1 : 1
      )
    )

    const statusText = computed(() => props.orderInfo.upwardOrderStatusID ?? 'n/a')
    const orderDate = computed(() => props.orderInfo?.createDate && formatDate(props.orderInfo.createDate))
    function formatDate(d: Date) {
      return dayjs(d).format('MMM D, YYYY h:mm a')
    }
    const orderTitle = computed(() => SectionNameConversion[props.order.upwardOrderType ?? ''])

    onMounted(() => {
      store.dispatch.programTypes.fetchAll(false) //go ahead and cache these up
    })

    return {
      statusText,
      trackingNumbers,
      shippingMethods,
      shipDate,
      orderDate,
      statusList,
      formatDate,
      orderTitle,
    }
  },
})
