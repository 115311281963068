import { RouteConfig } from 'vue-router'
import { idExpression } from '@/lib/support/router/idHelper'

const History = () => import('@/views/Programs/Communications/History.vue')
const Settings = () => import('@/views/Programs/Communications/Settings.vue')
const Email = () => import('@/views/Programs/Communications/Email.vue')
const Sms = () => import('@/views/Programs/Communications/Sms.vue')
const Communications = () => import('@/views/Programs/Communications/Communications.vue')
const GroupMessages = () => import('@/views/Programs/Communications/GroupMessages.vue')
const Scheduled = () => import('@/views/Programs/Communications/Scheduled.vue')
const Failures = () => import('@/views/Programs/Communications/Failures.vue')
const TeamPageAlerts = () => import('@/views/Programs/Communications/TeamPageAlerts.vue')

export default function (programType: string) {
  programType = programType ?? 'league'
  const routes = [
    {
      path: 'communications',
      component: Communications,
      children: [
        {
          path: '/',
          redirect: `/programs/${programType}/${idExpression}/communications/email`,
        },
        {
          path: 'email',
          component: Email,
        },
        {
          path: 'sms',
          component: Sms,
        },
        {
          path: 'failures',
          component: Failures,
        },
        { path: 'groupMessages', component: GroupMessages },
        { path: 'scheduled', component: Scheduled },
        {
          path: 'history',
          component: History,
        },
        { path: 'settings', component: Settings },
        { path: 'teamPageAlerts', component: TeamPageAlerts },
      ],
    },
  ] as RouteConfig[]
  return routes
}
