import { xRunningOrder } from 'src/GeneratedTypes/xOrder/xRunningOrder'
import { OrderProduct } from 'src/models/Order/OrderProduct'
import { xRunningOrderLine } from 'src/GeneratedTypes/xOrder/xRunningOrderLine'

export interface xRunningOrderExt extends xRunningOrder {
  products: OrderProduct[]
}

export interface xRunningOrderLineExt extends xRunningOrderLine {
  cmsProductDescription: string | null
  cmsProductImageUrls: string[] | null
  cmsProductName: string | null
  cmsSizingGuideUrl: string | null
  cmsUnitDescription: string | null
  colorDescription: string | null
  sizeDescription: string | null
}

export interface CFormatRunningOrder extends Omit<xRunningOrder, 'orderLines'> {
  orderLinesExt: xRunningOrderLineExt[]
  // seems this is present but null
  orderLines: null
  products: OrderProduct[] | null | undefined
}

export interface RunningOrderLineRollupInfo {
  internalOrderPosition: number
  orderTypeID: string | null
  warehouseID: string | null
  typeProgramID: string | null
  upwardProductID: string | null
  productDescription: string | null
  quantity: number
  unitCost: number
  extendedCost: number
  orderTypeName: string | null
  warehouseName: string | null
  programName: string | null
}

export interface xRunningOrderDetail {
  typeProgramID: string
  upwardOrderType: string
  upwardChallengeID: string
  orderCount: number
  participants: xRunningOrderDetailIndividual[]
}

export interface xRunningOrderDetailIndividual {
  individualID: number
  name: string
  products: xRunningOrderDetailParticipantProduct[]
}

export interface xRunningOrderDetailParticipantProduct {
  upwardProductID: string
  parentUpwardProductID: string
  typeProductOptionID: string
  typeColorID: string
  typeSizeID: string
  quantity: number
  isRequired: boolean
  cmsProductName: string
  cmsProductDescription: string
  cmsProductImageUrls: string[]
}

export interface xRunningOrderDetailAddonProduct {
  id: number
  productID: number
  upwardProductID: string
  parentUpwardProductID: string
  typeColorID: string
  typeSizeID: string
  quantity: number
  cmsProductName: string
  cmsProductDescription: string
  cmsProductImageUrls: string[]
}

export const ORDER_COUNT_NOT_INITIALIZED = 99e1
export function getEmptyRunningOrderDetail(challenge: string) {
  return {
    typeProgramID: 'NONE',
    upwardOrderType: 'RUNNINGORDER',
    upwardChallengeID: challenge,
    orderCount: ORDER_COUNT_NOT_INITIALIZED, // possible that this was throwing us into the wrong state.
    participants: [],
  } as xRunningOrderDetail
}

export function getEmptyXRunningOrderDetailParticipantProduct(): xRunningOrderDetailParticipantProduct {
  return {
    upwardProductID: '',
    parentUpwardProductID: '',
    typeProductOptionID: 'INCLUDED',
    typeColorID: '',
    typeSizeID: '',
    quantity: 0,
    isRequired: true,
    cmsProductName: '',
    cmsProductDescription: '',
    cmsProductImageUrls: [],
  }
}

export function getEmptyxRunningOrderDetailIndividual(): xRunningOrderDetailIndividual {
  return {
    individualID: 0,
    name: '',
    products: [],
  }
}
