import { CommunicationCriteria } from '@/GeneratedTypes/Communication/CommunicationCriteria'

export function getEmptyCommunicationCriteria(): CommunicationCriteria {
  return {
    upwardLeagueID: '',
    accountNumber: '',
    teamID: '',
    divisionID: '',
    typeProgramID: '',
    selectedGroups: [],
    teams: [],
    individualContactInfo: [],
    practiceNight: 'All',
    startGrade: 'K3',
    endGrade: '12th',
    paymentStatus: 'All',
    evalStatus: 'All',
    coachTeamStatus: 'All Approved',
    // approvalStatus: '',
    viewAllLeagueContacts: false,
    practiceFacilityIDs: [],
    headCoachOnly: false,
    gender: 'E',
  }
}
