import { cloneDeep } from 'lodash'
import { TypeLeagueVolunteerRole } from '@/GeneratedTypes/TypeLeagueVolunteerRole'
import { LeaguePlayerUDFDefinition } from '@/GeneratedTypes/LeaguePlayerUDFDefinition'
import { LeaguePracticeNight } from '@/GeneratedTypes/LeaguePracticeNight'
import { LeagueProgram } from '@/GeneratedTypes/LeagueProgram'
import { LeagueAccount } from '@/GeneratedTypes/LeagueAccount'
import { League } from '@/GeneratedTypes/League'
import { Camp } from '@/GeneratedTypes/Camp'
import { Clinic } from '@/GeneratedTypes/Clinic'
import { Connect } from '@/GeneratedTypes/Connect'
import { PartnerChallenge } from '@/models/UpwardRunning/PartnerChallenge'
import { LeagueTypeEnum } from '@/models/LeagueAbstraction/enums/LeagueTypeEnum'
import { SavedProductOfferingConfig } from '@/GeneratedTypes/SavedProductOfferingConfig'
import { getEmptySavedProductOfferingConfig } from '@/lib/support/models/SaveProductOfferingConfig/SavedProductOfferingConfig'
import { LeagueAccountProgramGenderGrade } from '@/GeneratedTypes/LeagueAccountProgramGenderGrade'
import { LeagueTypeGradeInfo } from 'src/GeneratedTypes/ListInfo/LeagueTypeGradeInfo'

/**
 * A quick reference to current properties of the current program type (connect, clinic, running, league, etc)
 * 99% of what gets used in API calls are upwardLeagueID, id, and programs names.
 *
 */
export interface LeagueAbstraction {
  type: LeagueTypeEnum
  id: number
  typeLeagueID: string
  leagueName: string
  publicDisplayName: string | null
  upwardLeagueID: string | null //Can be UWP or UR id. Used for primary id across all types (league, clinic, running, etc)
  accounts: LeagueAccount[] | null
  programs: LeagueProgram[] | null
  practiceNights: LeaguePracticeNight[] | null
  playerUDFs: LeaguePlayerUDFDefinition[] | null
  roles: TypeLeagueVolunteerRole[] | null
  productSeasonDate: Date | null // date used to determine the begining of the product year
  productOfferingConfig: SavedProductOfferingConfig
  lateDate: Date | null
  genderGrades: LeagueAccountProgramGenderGrade[]
  allowWaitlist: boolean
  ageCutoffDate: Date | null
  gradeDescriptions: LeagueTypeGradeInfo[] | null
}

export function getEmptyLeagueAbstraction(): LeagueAbstraction {
  return {
    leagueName: '',
    id: 0,
    playerUDFs: [],
    practiceNights: [],
    roles: [],
    accounts: [],
    programs: [],
    typeLeagueID: '',
    publicDisplayName: '',
    upwardLeagueID: '',
    type: LeagueTypeEnum.LEAGUE,
    productSeasonDate: null,
    productOfferingConfig: getEmptySavedProductOfferingConfig(),
    lateDate: null,
    genderGrades: [],
    allowWaitlist: false,
    ageCutoffDate: null,
    gradeDescriptions: [],
  }
}

export function leagueToAbstraction(l: League): LeagueAbstraction {
  return {
    leagueName: l.leagueName,
    id: l.id,
    playerUDFs: l.playerUDFs,
    practiceNights: l.practiceNights,
    roles: l.roles,
    accounts: l.accounts,
    programs: l.programs,
    typeLeagueID: l.typeLeagueID,
    publicDisplayName: l.publicDisplayName,
    upwardLeagueID: l.upwardLeagueID,
    type: LeagueTypeEnum.LEAGUE,
    productSeasonDate: l.firstPracticeDate,
    productOfferingConfig: l.productOfferingConfig ?? getEmptySavedProductOfferingConfig(),
    lateDate: l.lateDate,
    genderGrades: l.accounts && l.accounts[0].grades ? cloneDeep(l.accounts[0].grades) : [],
    allowWaitlist: l.registrationInfo?.allowWaitlist ?? false,
    ageCutoffDate: l.ageCutoffDate,
    gradeDescriptions: l.gradeDescriptions,
  }
}

export function campToAbstraction(l: Camp): LeagueAbstraction {
  return {
    leagueName: l.leagueName,
    id: l.id,
    playerUDFs: l.playerUDFs,
    practiceNights: [],
    roles: l.roles,
    accounts: l.accounts,
    programs: l.programs,
    typeLeagueID: l.typeLeagueID,
    publicDisplayName: l.publicDisplayName,
    upwardLeagueID: l.upwardLeagueID,
    type: LeagueTypeEnum.CAMP,
    productSeasonDate: l.campStartDate,
    productOfferingConfig: l.productOfferingConfig ?? getEmptySavedProductOfferingConfig(),
    lateDate: l.lateDate,
    genderGrades: l.accounts && l.accounts[0].grades ? cloneDeep(l.accounts[0].grades) : [],
    allowWaitlist: l.registrationInfo?.allowWaitlist ?? false,
    ageCutoffDate: l.ageCutoffDate,
    gradeDescriptions: l.gradeDescriptions,
  }
}

export function clinicToAbstraction(l: Clinic): LeagueAbstraction {
  return {
    leagueName: l.leagueName,
    id: l.id,
    playerUDFs: null,
    practiceNights: [],
    roles: null,
    accounts: null,
    programs: null,
    typeLeagueID: l.typeLeagueID,
    publicDisplayName: null,
    upwardLeagueID: l.upwardLeagueID,
    type: LeagueTypeEnum.CLINIC,
    productSeasonDate: null,
    productOfferingConfig: getEmptySavedProductOfferingConfig(),
    lateDate: null,
    genderGrades: [],
    allowWaitlist: false,
    ageCutoffDate: null,
    gradeDescriptions: l.gradeDescriptions,
  }
}

export function connectToAbstraction(l: Connect): LeagueAbstraction {
  return {
    leagueName: l.leagueName,
    id: l.id,
    playerUDFs: null,
    practiceNights: [],
    roles: null,
    accounts: null,
    programs: null,
    typeLeagueID: l.typeLeagueID,
    publicDisplayName: null,
    upwardLeagueID: l.upwardLeagueID,
    type: LeagueTypeEnum.CONNECT,
    productSeasonDate: null,
    productOfferingConfig: getEmptySavedProductOfferingConfig(),
    lateDate: null,
    genderGrades: [],
    allowWaitlist: false,
    ageCutoffDate: null,
    gradeDescriptions: l.gradeDescriptions,
  }
}

export function runningToAbstraction(l: PartnerChallenge): LeagueAbstraction {
  return {
    leagueName: l.challengeName ?? '',
    id: l.id ?? 0,
    playerUDFs: null,
    practiceNights: [],
    roles: null,
    accounts: null,
    programs: null,
    typeLeagueID: l.typeLeagueID ?? '',
    publicDisplayName: null,
    upwardLeagueID: l.upwardChallengeID,
    type: LeagueTypeEnum.RUNNING,
    productSeasonDate: null,
    productOfferingConfig: getEmptySavedProductOfferingConfig(),
    lateDate: null,
    genderGrades: [],
    allowWaitlist: false,
    ageCutoffDate: null,
    gradeDescriptions: null,
  }
}
