
















































































































import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import SearchInput from '@/elements/SearchInput.vue'
import TextInput from '@/elements/TextInput.vue'
import Loading from '@/elements/Loading.vue'
import SelectInput from '@/elements/SelectInput.vue'
import { useHoldDialog, useOrderHistory } from '@/views/AdminDash/vues/OrderHistory/useOrderHistory'
import { OrderFindInfo } from '@/GeneratedTypes/Order/ListInfo/OrderFindInfo'
import ordersClient from '@/clients/ordersClient'
import HoldReasonDialog from '@/views/AdminDash/vues/OrderHistory/HoldReasonDialog.vue'
import OrderItem from '@/views/AdminDash/vues/OrderHistory/OrderItem.vue'
import Alert, { AlertTypeEnum } from '@/components/Alert.vue'

export default defineComponent({
  components: {
    Loading,
    TextInput,
    SelectInput,
    RadioGroupInput,
    HoldReasonDialog,
    OrderItem,
    SearchInput,
    Alert,
  },
  setup(props, ctx) {
    const validSorts = [
      { id: 'OrderTypeDescription', value: 'Order Type Description' },
      { id: 'CreateDate', value: 'Date' },
      { id: 'StatusDescription', value: 'Status Description' },
      { id: 'StatusDate', value: 'Status Date' },
      { id: 'UpwardProductGroupID', value: 'Product Group' },
    ]

    const validDirection = [
      { id: 'DESC', value: 'Highest First' },
      { id: 'ASC', value: 'Lowest First' },
    ]

    const validStatus = ref<{ id: string; value: string }[]>([{ id: '', value: '' }])
    const validType = ref<{ id: string; value: string }[]>([{ id: '', value: '' }])

    onMounted(async () => {
      const stats = await ordersClient.getValidOrderStati()
      const types = await ordersClient.getValidOrderTypes()
      validStatus.value.push(
        ...stats
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .map((x) => ({ id: x.upwardTypeID, value: x.description }))
      )

      validType.value.push(
        ...types
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .map((x) => ({ id: x.upwardTypeID, value: x.description }))
      )
    })

    const searchUpwardOrderID = ref('')
    const searchUpwardLeagueID = ref('')
    const searchOrderType = ref('')
    const searchOrderStatus = ref('')
    const searchField = ref('StatusDate')
    const searchDirection = ref('DESC')
    const currentPage = ref(0)
    const pageSize = 10

    const areWeReadyToSearch = computed(
      () =>
        searchUpwardOrderID.value ||
        searchUpwardLeagueID.value ||
        searchOrderType.value ||
        searchOrderStatus.value
    )
    const uh = useOrderHistory()
    //basically if we have an even multiple of page and pagesize then probably more records exist.
    const hasMore = computed(() => uh.orderHistoryRef.value.length == pageSize * (currentPage.value + 1))

    function runSearchClicked() {
      currentPage.value = 0
      runSearch()
    }

    function loadMoreClicked() {
      currentPage.value++
      runSearch()
    }

    function runSearch() {
      uh.searchOrders({
        sortDirection: searchDirection.value,
        sort: searchField.value,
        upwardLeagueID: searchUpwardLeagueID.value,
        typeOrderID: searchOrderType.value,
        pageIndex: 1,
        pageSize: pageSize * (currentPage.value + 1),
        upwardOrderID: searchUpwardOrderID.value,
        typeOrderStatusID: searchOrderStatus.value,
      })
    }
    const errorMessage = ref('')

    async function clicked(o: OrderFindInfo) {
      if (o.typeOrderID && o.typeOrderID.startsWith('RUNNING')) {
        await ctx.root.$router.push(
          `../../admin-dashboard/order-search/running-order-detail/${o.upwardProgramID}/${o.upwardOrderID}`
        )
      } else {
        await ctx.root.$router.push(
          `../../admin-dashboard/order-search/order-detail/${o.upwardLeagueID}/${o.upwardOrderID}`
        )
      }
    }

    const uho = useHoldDialog()
    const releaseMessage = ref('')
    const showReleaseMessage = ref(false)
    async function releaseOrder(o: OrderFindInfo, reason: string) {
      try {
        await uho.release(o, reason)
        releaseMessage.value = 'Order Released'
        showReleaseMessage.value = true
      } catch (e) {
        throw e
      } finally {
        runSearch()
      }
    }

    const loading = ref(false)

    return {
      ...uh,
      hasMore,
      releaseOrder,
      showReleaseMessage,
      releaseMessage,
      ...uho,
      areWeReadyToSearch,
      loading,
      pageSize,
      currentPage,
      runSearchClicked,
      loadMoreClicked,
      validStatus,
      validType,
      validDirection,
      validSorts,
      errorMessage,
      clicked,
      runSearch,
      searchUpwardOrderID,
      searchUpwardLeagueID,
      searchOrderType,
      searchOrderStatus,
      searchField,
      searchDirection,
      alertType: AlertTypeEnum.NOTICE,
    }
  },
})
