import { xRunningOrderExt } from '@/models/UpwardRunning/Order/xRunningOrderExt'
import { xRunningOrder } from '@/GeneratedTypes/xOrder/xRunningOrder'
import { xRunningOrderLine } from '@/GeneratedTypes/xOrder/xRunningOrderLine'
import { OrderProduct } from 'src/models/Order/OrderProduct'
import { OrderProductSize } from 'src/models/Order/OrderProductSize'
import { cloneDeep } from 'lodash'
import { getEmptyOrderProduct } from '@/lib/support/models/GeneratedTypes/xOrders/xOrderProduct'

export function ToXRunningOrder(order: xRunningOrderExt) {
  // Convert xRunningOrderExt to xRunningOrder
  const localOrder = cloneDeep(order)

  const orderLines: xRunningOrderLine[] = []
  localOrder.products.forEach((product: OrderProduct) => {
    product.productColorSizes?.forEach((size: OrderProductSize) => {
      //restrict to just ordered things.
      if (size.orderQuantity) {
        const orderLine = {
          productID: product.upwardProductID,
          typeProgramID: product.typeProgramID,
          typeSizeID: size.typeSizeID,
          typeColorID: size.typeColorID,
          quantity: size.orderQuantity,
          quantityFree: size.orderFreeQuantity,
        } as xRunningOrderLine
        orderLines.push(orderLine)
      }
    })
  })

  localOrder.orderLines = orderLines
  localOrder.products = []
  localOrder.processingInstructions = []

  return normalizeOrder(localOrder) as xRunningOrder
}

/**
 * Marks sales tax with a negative value to signal to order summary that it isn't ready
 * to calculate yet.
 * @param o
 */
export function setSalesTaxNotCalculatedYet(o: xRunningOrderExt | undefined) {
  if (o) {
    o.salesTax = -0.0001
  }
  return o
}

export function getSalesTaxNotCalculatedYet(o: xRunningOrderExt | undefined) {
  return o && o.salesTax < 0
}

/**
 * Removes promocard's type size id from the order process and makes it a unique id
 * @param order
 * @return adjusted lineitems on the order
 */
function normalizeOrder(order: xRunningOrderExt) {
  const rv = cloneDeep(order)
  const lines: xRunningOrderLine[] = []

  ;(rv.orderLines ?? []).forEach((line) => {
    lines.push({ ...cloneDeep(line) })
  })
  return { ...rv, orderLines: lines }
}

/**
 * Merge order with orderExt and return as xStartupOrderExt
 * order values overwrite orderExt
 * @param order
 * @param orderExt
 * @constructor
 */
export function ToXRunningOrderExt(order: xRunningOrder, orderExt: xRunningOrderExt) {
  //
  //
  const newOrderExt: xRunningOrderExt = getEmptyXRunningOrderExt()
  Object.assign(newOrderExt, orderExt, order)
  return cloneDeep(newOrderExt)
}

export function removeZeroQtyProducts(orderExt: xRunningOrderExt) {
  const localOrder = cloneDeep(orderExt)
  const products: OrderProduct[] = []
  let sizes: OrderProductSize[] = []

  localOrder.products.forEach((product: OrderProduct) => {
    sizes = []
    product.productColorSizes?.forEach((size: OrderProductSize) => {
      if (size.orderQuantity > 0) {
        sizes.push(size)
      }
    })
    if (sizes.length > 0) {
      product.productColorSizes = sizes
      products.push(product)
    }
  })
  localOrder.products = products
  return localOrder
}

export function getOrderProductTotal(orderExt: xRunningOrderExt): number {
  return orderExt.orderLines?.reduce((n, { extendedCost }) => n + extendedCost, 0) ?? 0
}

export function getEmptyXRunningOrderExt(): xRunningOrderExt {
  return {
    products: [getEmptyOrderProduct()],
    upwardOrderType: 'RUNNINGORDER',
    clientOrderDate: null,
    upwardOrderID: '',
    upwardChallengeID: '',
    upwardLeagueID: '',
    accountNumber: '',
    notes: '',
    purchaseOrderNumber: '',
    dueDate: null,
    salesTax: 0,
    typeOrderingContextID: '',
    typeLeagueID: '',
    typeProductGroupID: '',
    leagueDirectorPartnerID: 0,
    orderLines: [],
    shippingInfo: {
      shipTo: {
        name: '',
        attention: '',
        contactInfo: '',
        street1: '',
        street2: '',
        subdivision1: '',
        subdivision2: '',
        postalCode: '',
        countryCode: '',
      },
      shipMethods: [
        {
          id: '',
          description: '',
          extendedDescription: '',
          selected: false,
          cost: 0,
        },
      ],
    },
    processingInstructions: [],
  }
}
