import { defineActions, defineGetters, defineMutations } from 'direct-vuex'
import { getFilteredProductList, OrderProduct } from '@/models/Order/OrderProduct'
import { OrderTypesEnum } from '@/lib/support/models/GeneratedTypes/xOrders/xOrdersGeneral'
import upwardRunningOrderClient, {
  ValidateRunningOrderShippingPayload,
} from '@/clients/upwardRunningOrderClient'
import upwardRunningClient, {
  PartnerChallengeCartRequest,
  PartnerChallengeParticipantCartChangeRequest,
} from '@/clients/upwardRunningClient'
import {
  getEmptyXRunningStartupOrderExt,
  setSalesTaxNotCalculatedYet,
  ToXRunningStartupOrderExt,
} from '@/lib/support/models/GeneratedTypes/xOrders/xRunningStartupOrderExt'
import { xRunningStartupOrder } from '@/GeneratedTypes/xOrder/xRunningStartupOrder'
import { xRunningStartupOrderExt } from '@/models/UpwardRunning/Order/xRunningStartupOrderExt'
import { xRunningOrderExt } from '@/models/UpwardRunning/Order/xRunningOrderExt'
import {
  xRunningOrderDetail,
  xRunningOrderDetailAddonProduct,
  xRunningOrderDetailParticipantProduct,
} from '@/models/UpwardRunning/Order/xRunningOrderExt'
import { getEmptyOrderProductSize, OrderProductSize } from '@/models/Order/OrderProductSize'
import { xShipToBase } from '@/GeneratedTypes/xOrder/xShipToBase'
import { xShipMethodBase } from '@/GeneratedTypes/xOrder/xShipMethodBase'
import { cloneDeep, remove } from 'lodash'
import { moduleActionContext } from '@/store/index'
import { RuntimeException } from '@/lib/common/exceptions/RuntimeException'
import { deleteCached, getCached, setCache } from '@/lib/support/store/orders/upwardRunningCache'
import { setProduct } from '@/lib/support/store/orders/operations'
import {
  UpwardRunningOrderState,
  UROSStates,
  newUROS,
  SizeUpdatedPayload,
  setProductInParticipants,
} from '@/lib/support/store/orders/upwardRunningOrderState'
import { PartnerChallengeCart } from '@/models/UpwardRunning/PartnerChallengeCart'
import { getEmptyXRunningOrderExt } from '@/lib/support/models/GeneratedTypes/xOrders/xRunningOrderExt'

interface OrderState {
  currentOrderTemplate?: xRunningStartupOrderExt
  lastCompletedOrder?: xRunningStartupOrderExt
  selectedProducts: OrderProductSelection[]
  selectedCards: number[]
  internalOrderTypeUpdateHack: number
  uros: UpwardRunningOrderState
}

export interface OrderProductSelection {
  upwardProductID: string
  variation: string
  id: number
}

const orderState: OrderState = {
  currentOrderTemplate: undefined,
  lastCompletedOrder: undefined,
  selectedProducts: [],
  selectedCards: [],
  internalOrderTypeUpdateHack: 1,
  uros: newUROS(''),
}

export enum getterNames {
  currentOrderTemplate = 'currentOrderTemplate',
  lastCompletedOrder = 'lastCompletedOrder',
  currentOrderProducts = 'currentOrderProducts',
  currentOrderShipTo = 'currentOrderShipTo',
  currentOrderShippingMethods = 'currentOrderShippingMethods',
  currentOrderNotes = 'currentOrderNotes',
  currentOrderInternalOrderType = 'currentOrderInternalOrderType',
  currentOrderAdminNote = 'currentOrderAdminNote',
  productsSelected = 'productsSelected',
  cardsSelected = 'cardsSelected',
  uros = 'uros',
}

class RunningOrderRuntimeException extends RuntimeException {
  name = 'Running Order Problem'
}

const getterTree = defineGetters<OrderState>()({
  currentOrderTemplate: (state) => state.currentOrderTemplate,
  lastCompletedOrder: (state) => state.lastCompletedOrder,
  currentOrderProducts: (state) =>
    cloneDeep(state.currentOrderTemplate?.products)
      ?.filter((p) => p.isActive)
      ?.sort((x, y) => (x.sortOrder == y.sortOrder ? 0 : x.sortOrder > y.sortOrder ? 1 : -1)) || [],
  currentOrderShipTo: (state) => state.currentOrderTemplate?.shippingInfo?.shipTo || {},
  currentOrderNotes: (state) => state.currentOrderTemplate?.notes || '',
  currentOrderShippingMethods: (state) => state.currentOrderTemplate?.shippingInfo?.shipMethods || [],
  currentOrderInternalOrderType: (state) => {
    const hack = state.internalOrderTypeUpdateHack
    const pi = state.currentOrderTemplate?.processingInstructions?.find(
      (x) => x.type == 'SET_INTERNAL_ORDERTYPE'
    )
    return pi && hack > 0 ? pi.value : null //hack will always be > 0
  },
  currentOrderAdminNote: (state) =>
    state.currentOrderTemplate?.processingInstructions?.find((x) => x.type == 'ADD_ADMINISTRATIVE_NOTE')
      ?.value ?? null,
  productsSelected: (state) => state.selectedProducts,
  cardsSelected: (state) => state.selectedCards,
  uros: (state) => state.uros,
})

export enum mutationNames {
  setCurrentOrderTemplate = 'setCurrentOrderTemplate',
  setLastCompletedOrder = 'setLastCompletedOrder',
  setCurrentOrderProducts = 'setCurrentOrderProducts',
  setCurrentOrderShipTo = 'setCurrentOrderShipTo',
  setCurrentOrderShippingMethods = 'setCurrentOrderShippingMethods',
  setCurrentOrderNotes = 'setCurrentOrderNotes',
  setCurrentOrderInternalOrderType = 'setCurrentOrderInternalOrderType',
  setCurrentOrderAdminNote = 'setCurrentOrderAdminNote',
  setSelectedProducts = 'setSelectedProducts',
  setPurchaseOrderNumber = 'setPurchaseOrderNumber',
  setCardsSelected = 'setCardsSelected',
  setCurrentOrderSelectedProducts = 'setCurrentOrderSelectedProducts',
  resetCurrentOrder = 'resetCurrentOrder',
  resetSalesTaxAndShipping = 'resetSalesTaxAndShipping',
  setCurrentStep = 'setCurrentStep',
  eraseState = 'eraseState',
  setLoading = 'setLoading',
  setTemplate = 'setTemplate',
  setOrderStatus = 'setOrderStatus',
  setPendingAddOnItems = 'setPendingAddOnItems',
  addPendingAddOnItems = 'addPendingAddOnItems',
  setParticipantProductLoading = 'setParticipantProductLoading',
  setProductSize = 'setProductSize',
  setValidated = 'setValidated',
}

/**
 * Cache key creation based on the parameter of challenge id.
 * Prior keys did not pull new orders templates on league change.
 * @param orderType
 * @param upwardChallengeID
 */
function makeKey(orderType: OrderTypesEnum, upwardChallengeID: string | null) {
  return `${orderType}-${upwardChallengeID ?? ''}}`
}

interface CardDescriptionType {
  name: string
  id: string
}

const mutations = defineMutations<OrderState>()({
  resetSalesTaxAndShipping(state, { orderType }: { orderType: OrderTypesEnum }) {
    state.currentOrderTemplate = setSalesTaxNotCalculatedYet(cloneDeep(state.currentOrderTemplate))
    setCache(
      makeKey(orderType, state.currentOrderTemplate?.upwardChallengeID ?? ''),
      state.currentOrderTemplate || getEmptyXRunningStartupOrderExt()
    )
  },
  /**
   * Clear out the old orders detail and zero out sales tax and shipping.
   * @param state
   * @param orderType
   */
  resetCurrentOrder(state, { orderType }: { orderType: OrderTypesEnum }) {
    state.currentOrderTemplate = setSalesTaxNotCalculatedYet(cloneDeep(state.currentOrderTemplate))
    if (state.currentOrderTemplate) {
      state.currentOrderTemplate.orderLines = []
      const products = cloneDeep(state.currentOrderTemplate?.products ?? [])

      products.forEach((x, index) => {
        state.currentOrderTemplate!.products.splice(index, 1, {
          ...x,
          productColorSizes: cloneDeep(x.productColorSizes ?? []).map((u) => ({
            ...u,
            orderQuantity: 0,
            orderFreeQuantity: 0,
          })),
        })
      })
    }
    setCache(
      makeKey(orderType, state.currentOrderTemplate?.upwardChallengeID ?? ''),
      state.currentOrderTemplate || getEmptyXRunningStartupOrderExt()
    )
  },
  setCurrentOrderTemplate(
    state,
    { orderType, item }: { orderType: OrderTypesEnum; item: xRunningStartupOrderExt }
  ) {
    state.currentOrderTemplate = item
    setCache(makeKey(orderType, item.upwardChallengeID), item)
  },
  /**
   * Chooses the promo cards that are available for purchase,
   * we do this by creating fake variations which allow the product card
   * make those selectable.
   * @param state
   * @param cards
   */
  setCardsSelected(state, { cards }: { cards: CardDescriptionType[] }) {
    let prods = cloneDeep(state.currentOrderTemplate?.products ?? [])
    const promocard = cloneDeep(prods.find((x) => x.upwardProductID == 'PROMOCARD'))
    if (promocard) {
      promocard.productColorSizes = cards.map(
        (x) =>
          ({
            ...getEmptyOrderProductSize(),
            colorDescription: x.name,
            typeColorID: x.id.toString(),
          } as OrderProductSize)
      )
      prods = prods.filter((x) => x.upwardProductID != 'PROMOCARD')
      prods.push(promocard)
      if (state.currentOrderTemplate) {
        state.currentOrderTemplate.products = prods

        setCache(
          makeKey(
            state.currentOrderTemplate.upwardOrderType as OrderTypesEnum,
            state.currentOrderTemplate.upwardChallengeID
          ),
          state.currentOrderTemplate
        )
      }
    }
  },
  setLastCompletedOrder(
    state,
    { orderType, item }: { orderType: OrderTypesEnum; item?: xRunningStartupOrderExt | null }
  ) {
    if (!orderType) return
    state.lastCompletedOrder = item ?? undefined
    if (item) {
      setCache(`${makeKey(orderType, item.upwardChallengeID)}_completed`, item)
    }
  },
  /**
   * These products are selected in the first round of product selection.
   * @param state
   * @param orderType
   * @param list
   */
  setCurrentOrderSelectedProducts(state, { list }: { list: OrderProductSelection[] }) {
    state.selectedProducts = cloneDeep(list)
  },

  setCurrentOrderProducts(state, { orderType, item }: { orderType: OrderTypesEnum; item: OrderProduct[] }) {
    if (state.currentOrderTemplate) {
      state.currentOrderTemplate.products = item
      state.currentOrderTemplate = setSalesTaxNotCalculatedYet(cloneDeep(state.currentOrderTemplate))
      setCache(
        makeKey(orderType, state.currentOrderTemplate?.upwardChallengeID ?? ''),
        state.currentOrderTemplate ?? getEmptyXRunningStartupOrderExt()
      )
    }
  },
  async setCurrentOrderShipTo(state, { orderType, item }: { orderType: OrderTypesEnum; item: xShipToBase }) {
    if (state.currentOrderTemplate && state.currentOrderTemplate.shippingInfo) {
      state.currentOrderTemplate.shippingInfo.shipTo = item
      await setCache(
        makeKey(orderType, state.currentOrderTemplate.upwardChallengeID),
        state.currentOrderTemplate
      )
    }
  },
  setCurrentOrderNotes(state, { orderType, item }: { orderType: OrderTypesEnum; item: string }) {
    if (state.currentOrderTemplate) {
      state.currentOrderTemplate.notes = item
      setCache(makeKey(orderType, state.currentOrderTemplate.upwardChallengeID), state.currentOrderTemplate)
    }
  },
  setPurchaseOrderNumber(state, { orderType, item }: { orderType: OrderTypesEnum; item: string }) {
    if (state.currentOrderTemplate) {
      state.currentOrderTemplate.purchaseOrderNumber = item
      setCache(makeKey(orderType, state.currentOrderTemplate.upwardChallengeID), state.currentOrderTemplate)
    }
  },
  setCurrentOrderShippingMethods(
    state,
    { orderType, item }: { orderType: OrderTypesEnum; item: xShipMethodBase[] }
  ) {
    if (state.currentOrderTemplate && state.currentOrderTemplate.shippingInfo) {
      state.currentOrderTemplate.shippingInfo.shipMethods = item
      setCache(makeKey(orderType, state.currentOrderTemplate.upwardChallengeID), state.currentOrderTemplate)
    }
  },
  setCurrentOrderInternalOrderType(
    state,
    { orderType, item }: { orderType: OrderTypesEnum; item: string | null }
  ) {
    if (state.currentOrderTemplate) {
      //remove existing processing instruction if it exists
      if (state.currentOrderTemplate.processingInstructions) {
        remove(state.currentOrderTemplate.processingInstructions, (x) => x.type == 'SET_INTERNAL_ORDERTYPE')
      }

      if (item != null && item != '') {
        //add
        if (!state.currentOrderTemplate.processingInstructions) {
          state.currentOrderTemplate.processingInstructions = []
        }

        state.currentOrderTemplate.processingInstructions.push({
          type: 'SET_INTERNAL_ORDERTYPE',
          value: item,
        })
      }

      state.internalOrderTypeUpdateHack++

      setCache(makeKey(orderType, state.currentOrderTemplate.upwardChallengeID), state.currentOrderTemplate)
    }
  },
  setCurrentOrderAdminNote(state, { orderType, item }: { orderType: OrderTypesEnum; item: string | null }) {
    if (state.currentOrderTemplate) {
      //remove existing processing instruction if it exists
      if (state.currentOrderTemplate.processingInstructions) {
        remove(state.currentOrderTemplate.processingInstructions, (x) => x.type == 'ADD_ADMINISTRATIVE_NOTE')
      }

      if (item != null && item != '') {
        //add
        if (!state.currentOrderTemplate.processingInstructions) {
          state.currentOrderTemplate.processingInstructions = []
        }

        state.currentOrderTemplate.processingInstructions.push({
          type: 'ADD_ADMINISTRATIVE_NOTE',
          value: item,
        })
      }

      setCache(makeKey(orderType, state.currentOrderTemplate.upwardChallengeID), state.currentOrderTemplate)
    }
  },
  setCurrentStep(state, { currentStep }: { currentStep: UROSStates }) {
    state.uros.currentStep = currentStep
  },
  eraseState(state) {
    state.uros = newUROS('')
  },
  setLoading(state, { loading }: { loading: boolean }) {
    state.uros.loading = loading
  },
  setTemplate(state, { template }: { template: xRunningOrderExt }) {
    state.uros.template = cloneDeep(template)
  },
  setOrderStatus(state, status: xRunningOrderDetail) {
    state.uros.orderStatus = cloneDeep(status)
  },
  setPendingAddOnItems(state, { items }: { items: xRunningOrderDetailAddonProduct[] }) {
    state.uros.pendingAddOnItems = items
  },
  addPendingAddOnItems(state, { item }: { item: xRunningOrderDetailAddonProduct }) {
    state.uros.pendingAddOnItems.push(item)
  },
  setParticipantProductLoading(
    state,
    { participantID, productID, loading }: { participantID: number; productID: string; loading: boolean }
  ) {
    const i = state.uros.productIndividualPending.indexOf(`${participantID}-${productID}`)
    if (i >= 0) {
      if (!loading) {
        state.uros.productIndividualPending.splice(i, 1)
      }
    } else if (loading) {
      state.uros.productIndividualPending.push(`${participantID}-${productID}`)
    }
  },
  setProductSize(
    state,
    { participantID, product }: { participantID: number; product: xRunningOrderDetailParticipantProduct }
  ) {
    const newStatus = cloneDeep(state.uros.orderStatus)
    newStatus.participants = setProductInParticipants(newStatus.participants, participantID, product)
    state.uros.orderStatus = newStatus
  },
  setValidated(state, { validated }: { validated: boolean }) {
    state.uros.validated = cloneDeep(validated)
  },
})

export enum actionNames {
  retrieveAndSetAsCurrent = 'retrieveAndSetAsCurrent',
  validateOrder = 'validateOrder',
  createOrder = 'createOrder',
  retrieveLastOrder = 'retrieveLastOrder',
  setCurrentOrderProducts = 'setCurrentOrderProducts',
  clearCachedOrder = 'clearCachedOrder',
  resetRunningOrder = 'resetRunningOrder',
  sizingUpdate = 'sizingUpdate',
  validateRunningOrder = 'validateRunningOrder',
  confirmOrder = 'confirmOrder',
}

const actions = defineActions({
  /**
   * Sets the orders quantity for a given product in the current orders.
   * @param state
   * @param context
   * @param orderType
   * @param productID - upward product id
   * @param colorID
   * @param sizeID
   * @param quantity - 0 for delete, otherwise the quantity
   */

  setProductQuantity(
    context,
    {
      orderType,
      productID,
      colorID,
      sizeID,
      quantity,
      quantityFree,
    }: {
      orderType: OrderTypesEnum
      productID: number
      colorID: string
      sizeID: string
      quantity: number
      quantityFree: number
    }
  ) {
    const { state, commit } = ordersActionContext(context)

    // when adding a product invalidate sales tax.
    commit.setCurrentOrderTemplate({
      orderType,
      item:
        setSalesTaxNotCalculatedYet(cloneDeep(state.currentOrderTemplate)) ??
        getEmptyXRunningStartupOrderExt(),
    })

    //then update quantity, size, color on product id.
    const products = cloneDeep(state.currentOrderTemplate?.products ?? [])

    commit.setCurrentOrderProducts({
      orderType,
      item: setProduct(products, { productID, sizeID, colorID, quantity, quantityFree }),
    })
  },

  /**
   * Grab an orders by type, if it exists in cache then continue to
   * use it, otherwise grab a template from the server.
   * Also, if the league has changed grab another orders template.
   * @param commit
   * @param context
   * @param id league id
   * @param orderType orders type from enum
   */
  async retrieveAndSetAsCurrent(
    context,
    { id, orderType }: { id: string; orderType: OrderTypesEnum }
  ): Promise<xRunningStartupOrderExt | null> {
    const { commit } = ordersActionContext(context)
    let orderData!: xRunningStartupOrderExt | null
    // Is orders cached in localStorage?
    orderData = getCached(makeKey(orderType, id))

    if (!orderData) {
      // Data doesn't exist in localStorage, fetch orders template from orders API
      orderData = (await upwardRunningOrderClient.retrieveTemplate(id, orderType)) as xRunningStartupOrderExt
    } else {
      //set initial selected products based on products with quantities.
      const curproducts = getFilteredProductList(orderData.products, () => true)
        .map((x) => ({ id: x.id, upwardProductID: x.upwardProductID, variation: '' }))
        .filter((x, i, a) => i == a.indexOf(x))
      commit.setCurrentOrderSelectedProducts({ list: curproducts })

      //
    }
    //by this time we have orders data.
    if (orderData) {
      commit.setCurrentOrderTemplate({ orderType, item: orderData })
      commit.resetCurrentOrder({ orderType: orderType })
    }
    return orderData
  },
  async validateOrder(
    { commit },
    { orderType, id }: { orderType: OrderTypesEnum; id: string }
  ): Promise<xRunningStartupOrder | null> {
    let result = null
    if (orderState.currentOrderTemplate) {
      result = await upwardRunningOrderClient.verificationDetails(id, orderState.currentOrderTemplate)
      if (result) {
        commit(mutationNames.setCurrentOrderTemplate, {
          orderType: orderType,
          item: ToXRunningStartupOrderExt(result, orderState.currentOrderTemplate),
        })
      }
    }
    return result
  },
  /***
   * Submit an orders and reset orders state.
   * @param commit
   * @param dispatch
   * @param id
   * @param orderType
   */
  async createOrder(
    { commit, dispatch },
    { id, orderType }: { id: string; orderType: OrderTypesEnum }
  ): Promise<xRunningStartupOrder | null> {
    let result = null

    if (orderState.currentOrderTemplate) {
      // place the orders

      if (
        orderState?.currentOrderTemplate?.orderLines?.some(
          (x) => !x.productUniqueID && x.productID == 'PROMOCARD'
        )
      ) {
        throw new RunningOrderRuntimeException(
          'There is a problem with the promo card, please remove the card and choose an alternative promocard variation.'
        )
      }

      result = await upwardRunningOrderClient.createOrder(id, orderState.currentOrderTemplate)

      if (result) {
        // put this orders in lastCompletedOrder. This info is displayed on confirmation page.
        commit(mutationNames.setLastCompletedOrder, {
          orderType: orderType,
          item: cloneDeep(ToXRunningStartupOrderExt(result, orderState.currentOrderTemplate)),
        })

        // delete the orders from localStorage
        deleteCached(makeKey(orderType, orderState.currentOrderTemplate.upwardChallengeID ?? ''))
        commit(mutationNames.resetCurrentOrder, { orderType: orderType })

        // repopulate currentOrderTemplate so it's ready for next orders
        await dispatch(actionNames.retrieveAndSetAsCurrent, {
          id: id,
          orderType: orderType,
        })
      }
    }
    return result
  },
  retrieveLastOrder(
    { commit },
    { orderType, upwardChallengeID }: { orderType: OrderTypesEnum; upwardChallengeID: string }
  ): void {
    let result = null
    result = getCached(`${makeKey(orderType, upwardChallengeID)}_completed`)
    commit(mutationNames.setLastCompletedOrder, {
      orderType: orderType,
      item: result,
    })
    return
  },
  clearCachedOrder(
    {},
    { orderType, upwardChallengeID }: { orderType: OrderTypesEnum; upwardChallengeID: string }
  ): void {
    deleteCached(makeKey(orderType, upwardChallengeID))
  },
  async resetOrder(
    context,
    {
      challenge,
    }: {
      challenge: string
    }
  ) {
    const { commit } = ordersActionContext(context)
    commit.eraseState()
    try {
      commit.setLoading({ loading: true })
      const template: xRunningOrderExt =
        ((await upwardRunningOrderClient.retrieveTemplate(
          challenge,
          OrderTypesEnum.running
        )) as xRunningOrderExt) ?? getEmptyXRunningOrderExt()

      const pendingProduct = await upwardRunningOrderClient.getPendingProduct(challenge)
      if (pendingProduct) {
        commit.setPendingAddOnItems({ items: pendingProduct })
      }

      const os = await upwardRunningOrderClient.getRunningOrderStatus(challenge)
      commit.setOrderStatus(os)

      commit.setTemplate({ template })
    } finally {
      commit.setLoading({ loading: false })
    }
  },
  async sizingUpdate(context, a: SizeUpdatedPayload) {
    const { commit } = ordersActionContext(context)
    commit.setParticipantProductLoading({
      participantID: a.participantID,
      productID: a.product.upwardProductID,
      loading: true,
    })
    const sendproduct: PartnerChallengeParticipantCartChangeRequest = {
      individualID: a.participantID,
      upwardProductID: a.product.upwardProductID,
      upwardSizeTypeID: a.product.typeSizeID,
    }
    try {
      commit.setLoading({ loading: true })
      //skip sending product sizes with NONE since we are handling that under the hood when the second call is made without the NONE size
      if (sendproduct.upwardSizeTypeID != 'NONE') {
        await upwardRunningClient.setParticipantCartProduct(a.upwardChallengeID, sendproduct)
      }
      commit.setProductSize({
        participantID: a.participantID,
        product: a.product,
      })
    } finally {
      //commit.setParticipantProduct
      commit.setParticipantProductLoading({
        participantID: a.participantID,
        productID: a.product.upwardProductID,
        loading: false,
      })
      commit.setLoading({ loading: false })
    }
  },
  async addOrUpdate_AddOnProduct(
    context,
    { upwId, product }: { upwId: string; product: xRunningOrderDetailAddonProduct }
  ) {
    const { commit, state } = ordersActionContext(context)
    try {
      commit.setLoading({ loading: true })
      const exist = state.uros.pendingAddOnItems.find((p) => findExistingProduct(p, product))
      let newProduct!: PartnerChallengeCart
      const request = {
        upwardProductID: product.upwardProductID,
        upwardColorTypeID: product.typeColorID,
        upwardSizeTypeID: product.typeSizeID,
        quantity: product.quantity,
      } as PartnerChallengeCartRequest

      if (exist) {
        // **** update existing add-on product ****
        newProduct = await upwardRunningClient.updateChallengeCartProduct(upwId, exist.id, request)

        //remove existing add-on product
        const clean = cloneDeep(state.uros.pendingAddOnItems).filter((p) => !findExistingProduct(p, product))
        commit.setPendingAddOnItems({ items: clean })
      } else {
        // **** add new  add-on product ****
        newProduct = await upwardRunningClient.createChallengeCartProduct(upwId, request)
      }
      console.log('TNT newProduct', newProduct, product)
      product.id = newProduct.id
      console.log('TNT product', product)
      commit.addPendingAddOnItems({ item: product })
    } finally {
      commit.setLoading({ loading: false })
    }
  },
  async remove_AddOnProduct(
    context,
    { upwId, product }: { upwId: string; product: xRunningOrderDetailAddonProduct }
  ) {
    const { commit, state } = ordersActionContext(context)
    try {
      commit.setLoading({ loading: true })

      //remove from store
      const clean = cloneDeep(state.uros.pendingAddOnItems).filter((p) => !findExistingProduct(p, product))
      commit.setPendingAddOnItems({ items: clean })

      //DELETE
      await upwardRunningClient.deleteChallengeCartProduct(upwId, product.id)
    } finally {
      commit.setLoading({ loading: false })
    }
  },
  async validateRunningOrder(context, p: ValidateRunningOrderShippingPayload) {
    const { commit, state } = ordersActionContext(context)
    try {
      commit.setLoading({ loading: true })
      commit.setValidated({ validated: false })
      const rv = await upwardRunningOrderClient.validateOrderShipping(p)

      if (rv) {
        commit.setTemplate({
          template: cloneDeep({
            ...state.uros.template,
            shippingInfo: rv.shippingInfo,
            notes: rv.notes,
            orderLines: rv.orderLines,
            purchaseOrderNumber: rv.purchaseOrderNumber,
            salesTax: rv.salesTax,
            upwardOrderID: rv.upwardOrderID,
          } as xRunningOrderExt),
        })
        commit.setValidated({ validated: true })
      }
    } finally {
      commit.setLoading({ loading: false })
    }
  },

  async confirmOrder(context, p: ValidateRunningOrderShippingPayload) {
    const { commit, state } = ordersActionContext(context)
    try {
      commit.setLoading({ loading: true })
      commit.setValidated({ validated: false })
      const rv = await upwardRunningOrderClient.confirmOrder(p)
      if (rv) {
        commit.setTemplate({
          template: cloneDeep({
            ...state.uros.template,
            shippingInfo: rv.shippingInfo,
            notes: rv.notes,
            purchaseOrderNumber: rv.purchaseOrderNumber,
            salesTax: rv.salesTax,
            orderLines: rv.orderLines,
            upwardOrderID: rv.upwardOrderID,
            clientOrderDate: rv.clientOrderDate,
          } as xRunningOrderExt),
        }) // } )
        commit.setValidated({ validated: true })
      }
    } finally {
      commit.setLoading({ loading: false })
    }
  },
})

function findExistingProduct(old: xRunningOrderDetailAddonProduct, newProd: xRunningOrderDetailAddonProduct) {
  return (
    old.upwardProductID == newProd.upwardProductID &&
    old.typeSizeID == newProd.typeSizeID &&
    old.typeColorID == newProd.typeColorID
  )
}

export const namespace = 'upwardRunningOrders'

export const upwardRunningOrders = {
  namespaced: true as true,
  state: orderState as OrderState,
  getters: getterTree,
  mutations,
  actions,
}

const ordersActionContext = (context: any) => moduleActionContext(context, upwardRunningOrders)
