import { xRunningStartupOrderExt } from '@/models/UpwardRunning/Order/xRunningStartupOrderExt'
import { xRunningStartupOrder } from '@/GeneratedTypes/xOrder/xRunningStartupOrder'
import { xStartupOrderLine } from '@/GeneratedTypes/xOrder/xStartupOrderLine'
import { OrderProduct } from 'src/models/Order/OrderProduct'
import { OrderProductSize } from 'src/models/Order/OrderProductSize'
import { cloneDeep } from 'lodash'
import { getEmptyOrderProduct } from '@/lib/support/models/GeneratedTypes/xOrders/xOrderProduct'

export function ToXRunningStartupOrder(order: xRunningStartupOrderExt) {
  // Convert xStartupOrderExt to xStartupOrder
  const localOrder = cloneDeep(order)

  const orderLines: xStartupOrderLine[] = []
  localOrder.products.forEach((product: OrderProduct) => {
    product.productColorSizes?.forEach((size: OrderProductSize) => {
      //restrict to just ordered things.
      if (size.orderQuantity) {
        const orderLine = {
          productID: product.upwardProductID,
          typeProgramID: product.typeProgramID,
          typeSizeID: size.typeSizeID,
          typeColorID: size.typeColorID,
          quantity: size.orderQuantity,
          quantityFree: size.orderFreeQuantity,
        } as xStartupOrderLine
        orderLines.push(orderLine)
      }
    })
  })

  localOrder.orderLines = orderLines
  localOrder.products = []
  localOrder.processingInstructions = []

  return normalizeOrder(localOrder) as xRunningStartupOrder
}

/**
 * Marks sales tax with a negative value to signal to order summary that it isn't ready
 * to calculate yet.
 * @param o
 */
export function setSalesTaxNotCalculatedYet(o: xRunningStartupOrderExt | undefined) {
  if (o) {
    o.salesTax = -0.0001
  }
  return o
}

export function getSalesTaxNotCalculatedYet(o: xRunningStartupOrderExt | undefined) {
  return o && o.salesTax < 0
}

/**
 * Removes promocard's type size id from the order process and makes it a unique id
 * @param order
 * @return adjusted lineitems on the order
 */
function normalizeOrder(order: xRunningStartupOrderExt) {
  const rv = cloneDeep(order)
  const lines: xStartupOrderLine[] = []

  ;(rv.orderLines ?? []).forEach((line) => {
    if (line.productID == 'PROMOCARD') {
      lines.push({
        ...cloneDeep(line),
        productUniqueID: line.typeColorID,
        typeColorID: 'N/A',
        typeSizeID: 'N/A',
      })
    } else {
      lines.push({ ...cloneDeep(line) })
    }
  })
  return { ...rv, orderLines: lines }
}

/**
 * Merge order with orderExt and return as xStartupOrderExt
 * order values overwrite orderExt
 * @param order
 * @param orderExt
 * @constructor
 */
export function ToXRunningStartupOrderExt(order: xRunningStartupOrder, orderExt: xRunningStartupOrderExt) {
  //
  //
  const newOrderExt: xRunningStartupOrderExt = getEmptyXRunningStartupOrderExt()
  Object.assign(newOrderExt, orderExt, order)
  return cloneDeep(newOrderExt)
}

export function removeZeroQtyProducts(orderExt: xRunningStartupOrderExt) {
  const localOrder = cloneDeep(orderExt)
  const products: OrderProduct[] = []
  let sizes: OrderProductSize[] = []

  localOrder.products.forEach((product: OrderProduct) => {
    sizes = []
    product.productColorSizes?.forEach((size: OrderProductSize) => {
      if (size.orderQuantity > 0) {
        sizes.push(size)
      }
    })
    if (sizes.length > 0) {
      product.productColorSizes = sizes
      products.push(product)
    }
  })
  localOrder.products = products
  return localOrder
}

export function getEmptyXRunningStartupOrderExt(): xRunningStartupOrderExt {
  return {
    products: [getEmptyOrderProduct()],
    upwardOrderType: 'RUNNINGSTARTUPORDER',
    clientOrderDate: null,
    upwardOrderID: '',
    upwardChallengeID: '',
    upwardLeagueID: '',
    accountNumber: '',
    notes: '',
    purchaseOrderNumber: '',
    dueDate: null,
    salesTax: 0,
    typeOrderingContextID: '',
    typeLeagueID: '',
    typeProductGroupID: '',
    orderLines: [],
    shippingInfo: {
      shipTo: {
        name: '',
        attention: '',
        contactInfo: '',
        street1: '',
        street2: '',
        subdivision1: '',
        subdivision2: '',
        postalCode: '',
        countryCode: '',
      },
      shipMethods: [
        {
          id: '',
          description: '',
          extendedDescription: '',
          selected: false,
          cost: 0,
        },
      ],
    },
    totalOrderedProductAmount: 0,
    totalParticipantCount: 0,
    processingInstructions: [],
  }
}
