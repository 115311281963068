import { defineGetters, defineActions, defineMutations } from 'direct-vuex'
import { ProductOfferingInfo } from '@/GeneratedTypes/ListInfo/ProductOfferingInfo'
import productOfferingClient from '@/clients/productOfferingClient'
import { SavedProductOfferingConfigDetail } from '@/GeneratedTypes/SavedProductOfferingConfigDetail'
import { OfferingOptionType } from '@/lib/support/models/UpwardTypes/OfferingOptionType'
import { TypeProductOption } from '@/lib/support/models/UpwardTypes/TypeProductOption'
import { getEmptySavedProductOfferingConfigDetail } from '@/lib/support/models/SavedProductOfferingConfigDetail/SavedProductOfferingConfigDetail'
import { SavedProductOfferingConfig } from '@/GeneratedTypes/SavedProductOfferingConfig'
import { ProductOfferingStartupProducts } from '@/models/ProductOfferingConfig/ProductOfferingStartupProducts'
import { SavedProductOfferingConfigStartupOrderProduct } from '@/GeneratedTypes/SavedProductOfferingConfigStartupOrderProduct'
import { SavedProductOfferingConfigGenderGrade } from 'src/GeneratedTypes/SavedProductOfferingConfigGenderGrade'
import { OfferingsByProgram } from '@/components/ProductFlow/models/OfferingsByProgram'
import { cloneDeep } from 'lodash'
import { ProductCategoryEnum } from '@/lib/common/LeagueProductCategories'
import { ProductOfferingCategoryInfo } from '@/GeneratedTypes/ListInfo/ProductOfferingCategoryInfo'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
import store from '@/store'

interface ProductOfferingState {
  productOfferingStartupProducts: ProductOfferingStartupProducts
  detailsStartupOrder: SavedProductOfferingConfigStartupOrderProduct[]
  productOfferingConfig: SavedProductOfferingConfig
  childProductsLoadedCount: number
  initialProductFlowLoadingComplete: boolean
  offeringsByProgram: OfferingsByProgram[]
  productCatalogs: ProductOfferingInfo[] // all categories from the BE
  displayableProductCatalogs: ProductOfferingInfo[] // categories visible in the UI
  selectedProductOfferings: ProductOfferingInfo[]
  selectedProductDetails: SavedProductOfferingConfigDetail[]
  configList: SavedProductOfferingConfig[] | null
}

const initState = (): ProductOfferingState => {
  return {
    productOfferingStartupProducts: {} as ProductOfferingStartupProducts,
    detailsStartupOrder: [] as SavedProductOfferingConfigStartupOrderProduct[],
    productOfferingConfig: {} as SavedProductOfferingConfig,
    initialProductFlowLoadingComplete: false,
    childProductsLoadedCount: 1,
    offeringsByProgram: [] as OfferingsByProgram[],
    productCatalogs: [] as ProductOfferingInfo[],
    displayableProductCatalogs: [] as ProductOfferingInfo[],
    selectedProductOfferings: [] as ProductOfferingInfo[],
    selectedProductDetails: [] as SavedProductOfferingConfigDetail[],
    configList: [] as SavedProductOfferingConfig[],
  }
}

const productOfferingState = initState()

export enum getterNames {
  availableProductGroups = 'availableProductGroups',
  availableStartupProducts = 'availableStartupProducts',
  productOfferingDetails = 'productOfferingDetails',
  productOfferingConfig = 'productOfferingConfig',
  selectedProductOfferingConfig = 'selectedProductOfferingConfig',
  pocStarterOrderItems = 'pocStarterOrderItems',
  detailsStartupOrder = 'detailsStartupOrder',
  hasAlertedChanges = 'hasAlertedChanges',
  childProductsLoadedCount = 'childProductsLoadedCount',
  initialProductFlowLoadingComplete = 'initialProductFlowLoadingComplete',
  detailsSport = 'detailsSport',
  detailsCheer = 'detailsCheer',

  offeringsByProgram = 'offeringsByProgram',
  selectedProductOfferings = 'selectedProductOfferings',
  productCatalogs = 'productCatalogs',
  displayableProductCatalogs = 'displayableProductCatalogs',
  selectedProductDetails = 'selectedProductDetails',
  offeringProgramTypes = 'offeringProgramTypes',
  configList = 'configList',
}

const getterTree = defineGetters<ProductOfferingState>()({
  [getterNames.availableProductGroups]: (state) => state.productOfferingStartupProducts.productOffering,
  [getterNames.availableStartupProducts]: (state) =>
    state.productOfferingStartupProducts.startupOrderProducts,
  [getterNames.productOfferingConfig]: (state) => state.productOfferingConfig,
  [getterNames.detailsStartupOrder]: (state) => state.detailsStartupOrder,
  [getterNames.pocStarterOrderItems]: (state) => state.productOfferingConfig.startupOrderProducts,

  /* Assemble all the pieces into a complete productOfferingConfig */
  [getterNames.selectedProductOfferingConfig]: () => buildSavedProductOfferingConfig(),

  [getterNames.childProductsLoadedCount]: (state) => state.childProductsLoadedCount,
  [getterNames.offeringsByProgram]: (state) => state.offeringsByProgram,
  [getterNames.initialProductFlowLoadingComplete]: (state) => state.initialProductFlowLoadingComplete,
  [getterNames.productCatalogs]: (state) => state.productCatalogs,
  [getterNames.displayableProductCatalogs]: (state) => state.displayableProductCatalogs,
  [getterNames.selectedProductOfferings]: (state) => state.selectedProductOfferings,
  [getterNames.selectedProductDetails]: (state) => state.selectedProductDetails,
  [getterNames.offeringProgramTypes]: (state) => {
    return getOfferingProgramTypes(state.productCatalogs)
  },
  [getterNames.configList]: (state) => state.configList,
})

export enum mutationNames {
  setProductOfferingStartupProducts = 'setProductOfferingStartupProducts',
  setDetailsOnSavedProductOffering = 'setDetailsOnSavedProductOffering',
  clear = 'clear',
  setOfferingId = 'setOfferingId',
  setProductOfferingConfig = 'setProductOfferingConfig',
  setPOTypeLeagueID = 'setPOTypeLeagueID',
  setAccountNumber = 'setAccountNumber',
  setDetailsStartupOrders = 'setDetailsStartupOrders',
  setFirstPracticeDateEstimate = 'setFirstPracticeDateEstimate',
  setGradeGender = 'setGradeGender',
  setHasAlertedChanges = 'setHasAlertedChanges',
  incrementChildProductsLoadedCount = 'incrementChildProductsLoadedCount',
  setInitialProductFlowLoadingComplete = 'setInitialProductFlowLoadingComplete',

  setOfferingByProgram = 'setOfferingByProgarm',
  setSelectedProductOfferings = 'setSelectedProductOfferings',
  setProductCatalog = 'setProductCatalog',
  setSelectedProductDetails = 'setSelectedProductDetails',
  setSelectedProductDetailsByGroup = 'setSelectedProductDetailsByGroup',
  removeFromSelectedProductDetailByOfferingId = 'removeFromSelectedProductDetailByOfferingId',
  clearSelectedProductDetails = 'clearSelectedProductDetails',
  setDisplayableCatalogs = 'setDisplayableCatalogs',
  setConfigList = 'setConfigList',
}

const mutations = defineMutations<ProductOfferingState>()({
  [mutationNames.setProductOfferingStartupProducts](
    state,
    { item }: { item: ProductOfferingStartupProducts }
  ) {
    state.productOfferingStartupProducts = item
  },
  [mutationNames.setProductOfferingConfig](state, { item }: { item: SavedProductOfferingConfig }) {
    state.productOfferingConfig = item
  },
  [mutationNames.setInitialProductFlowLoadingComplete](state, { status }: { status: boolean }) {
    state.initialProductFlowLoadingComplete = status
  },
  [mutationNames.setPOTypeLeagueID](state, { id }: { id: string }) {
    state.productOfferingConfig.typeLeagueID = id
  },
  [mutationNames.setFirstPracticeDateEstimate](
    state,
    { firstPracticeDateEstimate }: { firstPracticeDateEstimate: Date }
  ) {
    state.productOfferingConfig.firstPracticeDateEstimate = firstPracticeDateEstimate
  },
  [mutationNames.setGradeGender](
    state,
    { gradeGenders }: { gradeGenders: SavedProductOfferingConfigGenderGrade[] }
  ) {
    state.productOfferingConfig.gradesByGender = gradeGenders
  },
  [mutationNames.setOfferingId](state, { id }: { id: number }) {
    state.productOfferingConfig.id = id
  },
  [mutationNames.setAccountNumber](state, { id }: { id: string }) {
    state.productOfferingConfig.accountNumber = id
  },
  [mutationNames.incrementChildProductsLoadedCount](state) {
    state.childProductsLoadedCount++
  },
  [mutationNames.setDetailsStartupOrders](
    state,
    { details }: { details: SavedProductOfferingConfigStartupOrderProduct[] }
  ) {
    state.detailsStartupOrder = details
  },
  [mutationNames.clear](state) {
    Object.assign(state, initState())
  },
  [mutationNames.setDetailsOnSavedProductOffering](
    state,
    { details }: { details: SavedProductOfferingConfigDetail[] }
  ) {
    state.productOfferingConfig.details = details
  },

  [mutationNames.setOfferingByProgram](
    state,
    { offeringsByProgram }: { offeringsByProgram: OfferingsByProgram[] }
  ) {
    state.offeringsByProgram = offeringsByProgram
  },
  [mutationNames.setSelectedProductOfferings](
    state,
    { selectedOffering }: { selectedOffering: ProductOfferingInfo }
  ) {
    //remove old offering of this program type (aka cheer or not cheer) from selectedProductOfferings
    const cleanedOfferings = state.selectedProductOfferings.filter(
      (o) => o.isCheer != selectedOffering.isCheer
    )
    //add the incoming offering
    cleanedOfferings.push(selectedOffering)
    state.selectedProductOfferings = cleanedOfferings
  },
  [mutationNames.removeFromSelectedProductDetailByOfferingId](
    state,
    { selectedOffering }: { selectedOffering: ProductOfferingInfo }
  ) {
    removeOldDetails(state, selectedOffering)
  },
  [mutationNames.setProductCatalog](state, { productCatalog }: { productCatalog: ProductOfferingInfo }) {
    const catalog = cloneDeep(productCatalog)
    const catalogs = state.productCatalogs.filter((p) => p.typeProgramID != catalog.typeProgramID)
    catalogs.push(catalog)
    state.productCatalogs = catalogs
  },
  [mutationNames.setDisplayableCatalogs](state, { productCatalog }: { productCatalog: ProductOfferingInfo }) {
    const catalog = cloneDeep(productCatalog)
    //remove unnecessary categories
    if (catalog.categories) {
      catalog.categories = catalog.categories.filter(displayableCategoryFilter).filter(tierDiscoundFilter)
    }
    // remove this catalog if it already exists
    const catalogs = state.displayableProductCatalogs.filter((p) => p.typeProgramID != catalog.typeProgramID)
    //add the clean catalog
    catalogs.push(catalog)
    state.displayableProductCatalogs = catalogs
  },
  [mutationNames.setSelectedProductDetails](
    state,
    { productDetails }: { productDetails: SavedProductOfferingConfigDetail[] }
  ) {
    setSelectedProducts(state, null, productDetails)
  },
  [mutationNames.setSelectedProductDetailsByGroup](
    state,
    { productDetails }: { productDetails: { groupID: number; details: SavedProductOfferingConfigDetail[] } }
  ) {
    setSelectedProducts(state, productDetails.groupID, productDetails.details)
  },
  [mutationNames.setConfigList](state, { configList }: { configList: SavedProductOfferingConfig[] }) {
    state.configList = configList
  },
})

function setSelectedProducts(
  state: ProductOfferingState,
  groupID: number | null,
  productDetails: SavedProductOfferingConfigDetail[]
) {
  if (!productDetails.length) return

  const productOfferingId = productDetails[0].productOfferingID
  const categoryId = productDetails[0].categoryID
  const categories = state.selectedProductDetails.filter(
    (p) => !(p.categoryID == categoryId && p.productOfferingID == productOfferingId)
  )

  if (groupID && groupID > 0) {
    //also add in the products from this cateogry that are NOT in this group (yes this is ugly)
    const category = state.productCatalogs
      .find((x) => x.id == productOfferingId)
      ?.categories?.find((c) => c.categoryID == categoryId)
    if (category) {
      category.groups?.forEach((g) => {
        if (g.groupID != groupID) {
          category.products?.forEach((p) => {
            if (p.groupID != groupID) {
              const groupDetails = state.selectedProductDetails.filter(
                (d) =>
                  d.categoryID == categoryId &&
                  d.productOfferingID == productOfferingId &&
                  d.productID == p.productID
              )
              groupDetails.forEach((gd) => categories.push(gd))
            }
          })
        }
      })
    }
  }

  productDetails.forEach((pd) => {
    if (pd.productID) {
      categories.push(pd)
    }
  })
  state.selectedProductDetails = categories
}

function removeOldDetails(state: ProductOfferingState, selectedOffering: ProductOfferingInfo) {
  if (!selectedOffering) return
  //get the existing offering that matches this program type (aka cheer or not cheer) in selectedProductOfferings
  const oldOffering = state.selectedProductOfferings.find((o) => o.isCheer == selectedOffering.isCheer)
  //remove any details related to the old offering
  const details = cloneDeep(state.selectedProductDetails)
  if (oldOffering) {
    const cleanDetails = details.filter((d) => d.productOfferingID != oldOffering.id)
    state.selectedProductDetails = cleanDetails
  }
}

export enum actionNames {
  fetchTopLevelProducts = 'fetchTopLevelProducts',
  fetchChildOfferings = 'fetchChildOfferings',
  fetchProductOfferingConfig = 'fetchProductOfferingConfig',
  upsertOfferings = 'upsertOfferings',
  reset = 'reset',
  fetchConfigList = 'fetchConfigList',
}

const actions = defineActions({
  async [actionNames.fetchTopLevelProducts](
    { commit },
    {
      accountNumber,
      leagueType,
      firstPracticeDate,
    }: { accountNumber: string; leagueType: string; firstPracticeDate: Date | null }
  ): Promise<ProductOfferingStartupProducts | void> {
    const result = await productOfferingClient.retrieveTopLevelProducts(
      accountNumber,
      leagueType,
      firstPracticeDate
    )

    if (result) {
      commit(mutationNames.setProductOfferingStartupProducts, { item: result })
      return result
    }
  },

  async [actionNames.fetchChildOfferings](
    { commit },
    { id }: { id: number }
  ): Promise<ProductOfferingInfo | void> {
    const result = await productOfferingClient.retrieveChildOfferings(id)

    if (result) {
      commit(mutationNames.setProductCatalog, { productCatalog: result })
      commit(mutationNames.setDisplayableCatalogs, { productCatalog: result })
      commit(mutationNames.incrementChildProductsLoadedCount)

      return result
    }
  },
  async [actionNames.fetchProductOfferingConfig](
    { commit },
    { configId }: { configId: string }
  ): Promise<SavedProductOfferingConfig | void> {
    const result = await productOfferingClient.retrieveProductOfferingConfig(configId)

    if (result) {
      commit(mutationNames.setProductOfferingConfig, { item: result })
      return result
    }
  },
  async [actionNames.upsertOfferings]({ commit }): Promise<SavedProductOfferingConfig | null> {
    const result = await productOfferingClient.upsertOffering(buildSavedProductOfferingConfig())

    if (result) {
      if (result.data) commit(mutationNames.setOfferingId, { id: result.data.id })
      return result.data
    }
    return null
  },
  [actionNames.reset]({ commit }): void {
    commit(mutationNames.clear)
  },
  async [actionNames.fetchConfigList](
    { commit },
    { firstAccountNumber }: { firstAccountNumber: string }
  ): Promise<SavedProductOfferingConfig[] | null> {
    const result = await productOfferingClient.retrieveProductOfferingConfigList(firstAccountNumber, '')

    //Only show last 5 configs. (Items sorted by API)
    commit(mutationNames.setConfigList, { configList: result })
    return result
  },
})

function buildSavedProductOfferingConfig() {
  const poc = cloneDeep(productOfferingState.productOfferingConfig)
  if (productOfferingState.selectedProductOfferings.length) {
    poc.details = buildSavedProductOfferingConfigDetails()
    poc.typeProductGroupID = productOfferingState.selectedProductOfferings[0].typeProductGroupID
    poc.startupOrderProducts = cloneDeep(productOfferingState.detailsStartupOrder)
  }
  return poc
}

function buildSavedProductOfferingConfigDetails() {
  const autoIncludes = getAutoIncludes()
  const details = cloneDeep(productOfferingState.selectedProductDetails)
  return [...autoIncludes, ...details]
}

/*
 * The api requires that products in the autoinclude categories be converted
 * to SavedProductOfferingConfigDetail and added to the product config when saved
 */
function getAutoIncludes(): SavedProductOfferingConfigDetail[] {
  const all = [] as SavedProductOfferingConfigDetail[]

  //get all product catalogs
  const catalogs = cloneDeep(productOfferingState.productCatalogs)
  catalogs.forEach((pc) => {
    //for each category in a catalog
    pc.categories?.forEach((cat) => {
      //if the category is AUTOINCLUDE
      if (cat.typeProductOfferingOptionID === OfferingOptionType.AUTOINCLUDE) {
        //foreach product in an AUTOINCLUDE category
        cat.products?.forEach((p) => {
          // convert from ProductOfferingProductInfo to SavedProductOfferingConfigDetail
          all.push({
            ...getEmptySavedProductOfferingConfigDetail(),
            productOfferingID: pc.id,
            categoryID: cat.categoryID,
            productID: p.productID,
            typeProductOptionID: TypeProductOption.INCLUDED,
          } as SavedProductOfferingConfigDetail)
        })
      }
    })
  })
  return all
}

/*
 * Displayable Category Filters
 */

function displayableCategoryFilter(c: ProductOfferingCategoryInfo) {
  return (
    c.categoryID != ProductCategoryEnum.AUTOINCLUDE &&
    c.categoryID != ProductCategoryEnum.UPWARD_FEES &&
    c.categoryID != ProductCategoryEnum.UPWARD_DISCOUNT
  )
}

function tierDiscoundFilter(c: ProductOfferingCategoryInfo) {
  if (!c.tierRestriction) return true
  const partner = store.getters.partners.partnerInfo
  if (partner && partner.upwardTierID === c.tierRestriction) return true
  return false
}

function getOfferingProgramTypes(items: ProductOfferingInfo[]): UpwardProgramTypeID[] {
  const retval: UpwardProgramTypeID[] = []
  items.forEach((x) => {
    const prog = store.getters.programTypes.byUpwardTypeId(x.typeProgramID)
    if (prog && !retval.some((r) => r.id == prog.id)) {
      retval.push(prog)
    }
  })
  return retval
}

export const namespace = 'productOfferings'

export const productOfferings = {
  namespaced: true as true,
  state: productOfferingState,
  getters: getterTree,
  actions,
  mutations,
}
