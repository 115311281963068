interface PrettyLeagueTypes {
  [key: string]: string
}

export const prettyLeagueTypes: PrettyLeagueTypes = {
  LT_BASKETBALL: 'Basketball',
  LT_BASKETBALL_BASKETBALLCHEERLEADING: 'Basketball with Cheerleading',
  LT_SOCCER: 'Soccer',
  LT_FOOTBALL: 'Football',
  LT_BASKETBALLCAMP: 'Basketball Camp',
  LT_CHEERLEADINGCAMP: 'Cheerleading Camp',
  LT_SOCCERCAMP: 'Soccer Camp',
  LT_FOOTBALL_FOOTBALLCHEERLEADING: 'Football with Cheerleading',
  LT_FOOTBALLCAMP: 'Football Camp',
  LT_VOLLEYBALL: 'Volleyball',
  LT_BASEBALL: 'Baseball',
  LT_FOOTBALLONE: 'Football Clinic',
  LT_BASKETBALLONE: 'Basketball Clinic',
  LT_SOCCERONE: 'Soccer Clinic',
  LT_CHEERLEADINGONE: 'Cheerleading Clinic',
  LT_KICKBALLCONNECT: 'Kickball Connect',
  LT_DODGEBALLCONNECT: 'Dodgeball Connect',
  LT_BASKETBALLCONNECT: 'Basketball Connect',
  LT_FOOTBALLCONNECT: 'Football Connect',
  LT_SOCCERCONNECT: 'Soccer Connect',
  LT_FUTSALCONNECT: 'Futsal Connect',
  LT_UPWARDSELECTMSS: 'Upward Multi-Sport Select',
  LT_PICKLEBALL: 'Pickleball',
}
