







































































import { defineComponent, ref, computed, onMounted, watch } from '@vue/composition-api'
import Card from '@/components/Card.vue'
import CurrentPrograms from '@/components/CurrentPrograms.vue'
import PreviousEstimates from '@/components/PreviousEstimates.vue'
import TextInput from '@/elements/TextInput.vue'
import { useFeatureFlags } from '@/services/useFeatureFlags'

import contentManagementClient from '@/clients/contentManagementClient'
import { BannerContent } from '@/models/PageContent/BannerContent'
import store from '@/store'

export default defineComponent({
  name: 'Home',
  components: {
    Card,
    TextInput,
    CurrentPrograms,
    PreviousEstimates,
  },
  setup(props, ctx) {
    const { features } = useFeatureFlags()
    const searchTerm = ref('')
    const bannerItems = ref<BannerContent[] | null>(null)
    const currentBannerImg = ref(0)
    const configCount = ref(1)
    const configList = computed(() => store.getters.productOfferings.configList)

    async function getBannerItems() {
      bannerItems.value = await contentManagementClient.getGatewayBanners()
    }

    const visibleBannerItems = computed((): BannerContent[] => {
      if (!bannerItems.value) {
        return []
      }

      return bannerItems.value.filter((item) => showBannerItem(item))
    })

    function showBannerItem(bannerItem: BannerContent): boolean {
      if (!bannerItem || bannerItem === null) {
        return false
      }

      const today = new Date()
      const bothDatesAreNull = !bannerItem.displayStartDate && !bannerItem.displayEndDate
      const onlyStartDateIsUsedAndValid =
        bannerItem.displayStartDate && !bannerItem.displayEndDate && bannerItem.displayStartDate <= today
      const onlyEndDateIsUsedAndValid =
        bannerItem.displayEndDate && !bannerItem.displayStartDate && bannerItem.displayEndDate > today
      const bothStartAndEndUsedAndValid =
        bannerItem.displayStartDate &&
        bannerItem.displayEndDate &&
        bannerItem.displayStartDate <= today &&
        bannerItem.displayEndDate > today

      return (
        (bothDatesAreNull ?? false) ||
        (onlyStartDateIsUsedAndValid ?? false) ||
        (onlyEndDateIsUsedAndValid ?? false) ||
        (bothStartAndEndUsedAndValid ?? false)
      )
    }

    onMounted(async () => {
      if (store.getters.authorization.isCurrentUserAnAdmin) {
        ctx.root.$router.push('/admin-dashboard')
      }

      await getBannerItems()
    })

    watch(
      () => configList.value,
      () => {
        configCount.value = configList.value?.length ?? 0
      },
      { deep: true }
    )

    return {
      features,
      bannerItems,
      currentBannerImg,
      visibleBannerItems,
      searchTerm,
      configCount,
    }
  },
})
