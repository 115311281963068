













import SimpleGrid from '@/elements/SimpleGrid.vue'
import { GridColumn, GridColumnAlignEnum } from '@/models/Grid/GridColumn'
import currency from '@/filters/currency'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { CFormatRunningOrder, xRunningOrderLineExt } from '@/models/UpwardRunning/Order/xRunningOrderExt'
import { filterNA } from '@/models/Order/OrderProduct'
import { downloadCSV } from '@/lib/common/csv/downloadCSV'
import { RunningOrderProductInfo } from '@/GeneratedTypes/ListInfo/RunningOrderProductInfo'

export default defineComponent({
  components: {
    SimpleGrid,
  },
  props: {
    order: { type: Object as PropType<CFormatRunningOrder>, required: true },
    itemList: { type: Array as PropType<RunningOrderProductInfo[]>, required: false, default: () => [] },
  },
  setup(props) {
    const columns = computed(() => {
      const retval = [] as GridColumn[]
      retval.push({ field: 'productLabel', headerText: 'Item Name', width: 300 })
      retval.push({ field: 'type', headerText: 'Type' })
      if (orderList.value.some((x) => filterNA(x.size))) {
        retval.push({ field: 'size', headerText: 'Size' })
      }
      retval.push({ field: 'orderQuantity', headerText: 'Quantity' })
      retval.push({ field: 'cost', headerText: 'Cost', textAlign: GridColumnAlignEnum.RIGHT })
      if (orderList.value.some((x) => x.name)) {
        retval.push({ field: 'name', headerText: 'Name' })
      }
      return retval

      /*
      if (orderList.value.some((x) => filterNA(x.size))) {
        return [
          { field: 'productLabel', headerText: 'Item Name', width: 300 },
          { field: 'type', headerText: 'Type' },
          { field: 'size', headerText: 'Size' },
          { field: 'orderQuantity', headerText: 'Quantity' },
          { field: 'cost', headerText: 'Cost', textAlign: GridColumnAlignEnum.RIGHT },
          { field: 'name', headerText: 'Name' },
        ] as GridColumn[]
      } else {
        return [
          { field: 'productLabel', headerText: 'Item Name', width: 300 },
          { field: 'type', headerText: 'Type' },
          { field: 'orderQuantity', headerText: 'Quantity' },
          { field: 'cost', headerText: 'Cost', textAlign: GridColumnAlignEnum.RIGHT },
          { field: 'name', headerText: 'Name' },
        ] as GridColumn[]
      }
      */
    })

    function getProductLabel(x: xRunningOrderLineExt) {
      if (x.cmsProductName) {
        return x.cmsProductName
      }
      const ilProd = props.itemList.find(
        (p) => p.upwardProductID == x.productID && p.typeProgramID == x.typeProgramID
      )
      if (ilProd && ilProd.cmsProductName) {
        return ilProd.cmsProductName
      }

      //fallback to previous look into the original template
      if (props.order?.products) {
        const prod = props.order?.products?.find(
          (p) => p.upwardProductID == x.productID && p.typeProgramID == x.typeProgramID
        )
        if (prod && prod.cmsProductName) {
          return prod.cmsProductName
        }
      }
      if (x.productID) {
        return x.productID
      }
      return 'n/a'
    }

    function supressNA(s: string) {
      if (s == 'N/A') {
        return ''
      }
      return s
    }

    interface OrderListItem {
      productLabel: string
      program: string | null
      type: string
      size: string
      cost: string | null | undefined
      orderQuantity: number
      name: string
    }
    const orderList = computed(() =>
      (props.order?.orderLinesExt || props.order?.orderLines)
        ?.filter((x) => x.quantity)
        .map(
          (x) =>
            ({
              productLabel: getProductLabel(x),
              program: x.typeProgramID,
              type: supressNA(x.colorDescription || x.typeColorID || ''),
              size: supressNA(x.sizeDescription || x.typeSizeID || ''),
              cost: currency(x.extendedCost),
              orderQuantity: x.quantity,
              name: x.participantName || '',
            } as OrderListItem)
        )
    )

    function getCost(cost: number) {
      return currency(cost)
    }

    function download() {
      downloadCSV(columns.value, orderList.value, 'order_items.csv')
    }

    return { getCost, orderList, columns, download }
  },
})
