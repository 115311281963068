



















import { defineComponent, computed, ref, onMounted } from '@vue/composition-api'
import { SavedProductOfferingConfig } from '@/GeneratedTypes/SavedProductOfferingConfig'
import Loading from '@/elements/Loading.vue'
import store from '@/store'

export default defineComponent({
  name: 'PreviousEstimates',
  components: {
    Loading,
  },
  setup() {
    const firstAccountNumber = computed(() => store.getters.authorization.firstAccountNumber)

    const configList = ref<SavedProductOfferingConfig[] | null>([])
    const isLoading = ref(false)

    onMounted(async () => {
      isLoading.value = true
      try {
        configList.value = await store.dispatch.productOfferings.fetchConfigList({
          firstAccountNumber: firstAccountNumber.value,
        })
      } finally {
        isLoading.value = false
      }
    })

    return {
      isLoading,
      configList,
    }
  },
})
