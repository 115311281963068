



















































































import { defineComponent, ref, watch } from '@vue/composition-api'
import { OrderNoteInfo } from '@/GeneratedTypes/Order/ListInfo/OrderNoteInfo'
import OrderDetail from '@/components/UpwardRunning/OrderHistory/OrderDetail.vue'
import Loading from '@/elements/Loading.vue'
import IntegrationNotesDialog from './IntegrationNotesDialog.vue'
import { loadRunningOrder } from '@/lib/components/OrderDetail/loadOrder'
import { OrderInfoExt } from '@/models/Order/OrderInfoExt'
import OrderXMLEditDialog from './OrderXMLEditDialog.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import ordersClient from '@/clients/ordersClient'
import store from '@/store'
import { xRunningOrderExt } from '@/models/UpwardRunning/Order/xRunningOrderExt'
import { xRunningStartupOrderExt } from '@/models/UpwardRunning/Order/xRunningStartupOrderExt'

export default defineComponent({
  name: 'OrderDetailPage',
  components: { IntegrationNotesDialog, Loading, OrderDetail, OrderXMLEditDialog, ConfirmationModal },
  setup(props, ctx) {
    const currentOrder = ref<xRunningStartupOrderExt | xRunningOrderExt | null>(null)
    const currentOrderInfo = ref<OrderInfoExt | null>(null)
    const notes = ref<OrderNoteInfo[]>([])
    const loading = ref(false)
    const errorMessage = ref('')
    const showASBResendSection = ref(false)
    const showOrderSuperAdminOptions = ref(
      store.getters.authorization.isCurrentUserAnAdmin && !store.getters.authorization.isUpwardStaff
    )

    watch(
      () => ctx.root.$route,
      () => getOrderByRoute(),
      { immediate: true }
    )

    watch(
      () => notes.value,
      () => {
        showASBResendSection.value = notes.value.some((x) => x.noteText?.includes('@@RESEND TO ASB@@'))
      },
      { deep: true }
    )

    function clearErrorClicked() {
      alert('clear error TBD')
    }

    function integrationClicked() {
      internalNotesVisible.value = true
    }

    function editOrderXMLClicked() {
      internalXMLEditVisible.value = true
    }

    function resendASBClicked() {
      resendASBVisible.value = true
    }

    async function xmlEditClosed() {
      internalXMLEditVisible.value = false
      await getOrderByRoute()
    }

    async function getOrderByRoute() {
      const challenge = ctx.root.$route.params.id ?? ''
      const orderid = ctx.root.$route.params.orderid ?? ''
      try {
        loading.value = true

        await loadRunningOrder(challenge, orderid, currentOrder, currentOrderInfo, errorMessage)
        await refreshNotes(orderid)
      } catch (e) {
        errorMessage.value = e.errorObject?.message || e.message
        loading.value = false
        throw e
      }
      loading.value = false
    }

    async function refreshNotes(orderid: string) {
      notes.value =
        (await ordersClient.getNotesByUpwardOrderID(orderid))?.sort((a, b) => b.noteID - a.noteID) ?? []
    }

    async function integrationNotesChanged() {
      await refreshNotes(currentOrderInfo.value?.upwardOrderID ?? '')
    }

    async function resendToASBConfirmed(b: boolean) {
      if (b) {
        //attempt the resend
        const challenge = ctx.root.$route.params.id ?? ''
        const orderid = ctx.root.$route.params.orderid ?? ''
        try {
          loading.value = true
          await ordersClient.resendToASB(challenge, orderid)
        } catch (e) {
          errorMessage.value = e.errorObject?.message || e.message
          loading.value = false
          throw e
        }
        loading.value = false

        //refresh the notes if successful
        await refreshNotes(orderid)
        resendASBVisible.value = false
      } else {
        //they said no, so just close the modal
        resendASBVisible.value = false
      }
    }

    const internalNotesVisible = ref(false)
    const internalXMLEditVisible = ref(false)
    const resendASBVisible = ref(false)

    return {
      currentOrder,
      errorMessage,
      currentOrderInfo,
      notes,
      loading,
      integrationClicked,
      editOrderXMLClicked,
      clearErrorClicked,
      internalNotesVisible,
      integrationNotesClosed: () => (internalNotesVisible.value = false),
      integrationNotesChanged,
      internalXMLEditVisible,
      xmlEditClosed,
      showASBResendSection,
      resendASBVisible,
      resendASBClicked,
      resendToASBConfirmed,
      showOrderSuperAdminOptions,
    }
  },
})
