import { PreviewMessage } from '@/models/Communication/PreviewMessage'
import { EmailMessage } from '@/GeneratedTypes/Communication/EmailMessage'
import { Attachment } from '@/GeneratedTypes/Communication/Attachment'
import store from '@/store'
import { Recipient } from '@/views/Programs/Communications/model/Recipients'
import { getEmptyCommunicationCriteria } from '@/lib/support/models/Communication/CommunicationCriteria'
import { getEmptyEmailMessage } from '@/lib/support/models/Communication/EmailMessage'

export class CommunicationService {
  /**
   * Transforms a PreviewMessage into an EmailMessage for sending
   * @param preview The PreviewMessage to transform
   * @returns EmailMessage ready to be sent
   */
  static previewMessageToMessage(
    preview: PreviewMessage,
    attachment1: Attachment,
    attachment2: Attachment,
    sendCommunicationOn: Date | null,
    minutesToAdd: number | null,
    type: 'sendemail' | 'sendsms'
  ): EmailMessage {
    return {
      ...getEmptyEmailMessage(),
      accountNumber: store.getters.authorization.firstAccountNumber,
      upwardLeagueID: store.getters.leagueAbstraction.currentItem.upwardLeagueID,
      displayName: preview.displayName,
      replyTo: preview.replyTo,
      emailSubject: preview.emailSubject,
      messageBody: preview.messageBody,
      to: allRecipients(preview, type),
      attachments: createAttachementArray(attachment1, attachment2),
      sendCommunicationOn: sendCommunicationOn,
      minutesToAdd: minutesToAdd,
      recipientCriteria: {
        ...getEmptyCommunicationCriteria(),
        accountNumber: store.getters.authorization.firstAccountNumber,
        upwardLeagueID: store.getters.leagueAbstraction.currentItem.upwardLeagueID,
        selectedGroups: preview.selectedGroups,
        teams: preview.teams,
        practiceNight: preview.practiceNight,
        startGrade: preview.startGrade,
        endGrade: preview.endGrade,
        paymentStatus: preview.paymentStatus,
        evalStatus: preview.evalStatus,
        coachTeamStatus: preview.coachTeamStatus,
        typeProgramID: preview.typeProgramID,
        viewAllLeagueContacts: preview.viewAllLeagueContacts,
        practiceFacilityIDs: preview.practiceFacilityIDs,
        gender: preview.gender,
        individualContactInfo: preview.toDelimited
          ? preview.toDelimited
              .split(',')
              .map((contact) => contact.trim())
              .filter((contact) => contact !== '')
          : [],
      },
    }
  }
}

function allRecipients(preview: PreviewMessage, type: 'sendemail' | 'sendsms'): string[] {
  // Get emails from messagePreview.to and toDelimited
  const recipients = [...preview.to]
  if (preview.toDelimited) {
    recipients.push(...preview.toDelimited.split(',').filter((r) => r.trim() !== ''))
  }
  // Get emails from selected recipients in store
  const selectedRecipients = store.getters.communications.recipients
    .filter((recipient: Recipient) => recipient.isSelected)
    .map((recipient: Recipient) => (type === 'sendemail' ? recipient.email : recipient.phone))
  recipients.push(...selectedRecipients)
  // Remove duplicates and empty values, trim whitespace
  return [...new Set(recipients.map((r) => r.trim()))].filter((r) => r !== '')
}

function createAttachementArray(attachment1: Attachment, attachment2: Attachment): Attachment[] {
  const attachments: Attachment[] = []
  if (attachment1.name) {
    attachments.push(attachment1)
  }
  if (attachment2.name) {
    attachments.push(attachment2)
  }
  return attachments
}

export default CommunicationService
