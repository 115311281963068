import { EmailSMSAccount } from '@/GeneratedTypes/Communication/EmailSMSAccount'
import { PreviewMessage } from '@/models/Communication/PreviewMessage'
import communicationsClient from '@/clients/communicationsClient'
import { defineGetters, defineActions, defineMutations } from 'direct-vuex'
import { getEmptyPreviewMessage } from '@/lib/support/models/Communication/PreviewMessage'
import { CommunicationStatusData } from '@/models/Communication/CommunicationStatusData'
import store from '@/store'
import { CommunicationService } from '@/services/communicationService'
import { MessageChannelTypes } from '@/components/Communication/MessageChannelData'
import { EmailSMSLeagueSetting } from '@/GeneratedTypes/Communication/EmailSMSLeagueSetting'
import { Attachment, getEmptyAttachment } from '@/GeneratedTypes/Communication/Attachment'
import { Recipient } from '@/views/Programs/Communications/model/Recipients'
import upwardRunningClient from '@/clients/upwardRunningClient'

const getNewProviderInfo = () => {
  const EmailSMSProviderInfo: EmailSMSAccount = {
    emailSmsAccountID: -1,
    accountNumber: '',
    upwardLeagueID: '',
    emailAcctID: -1,
    emailAcctName: '',
    emailAcctApiKey: '',
    emailCredits: -1,
    emailMaxCredits: -1,
    isEmailAllowed: false,
    smsAcctID: -1,
    smsAcctName: '',
    smsAcctApiKey: '',
    smsCredits: -1,
    smsMaxCredits: -1,
    isSMSAllowed: false,
    smsTypeProviderID: -1,
    smsAssignedNumber: '',
  }

  return EmailSMSProviderInfo
}

interface CommunicationsState {
  currentProviderInfo: EmailSMSAccount
  messagePreview: PreviewMessage
  isTollFreeApplicationPending: boolean
  statuses: CommunicationStatusData[]
  messagePreviewLoading: boolean
  leagueSettings: EmailSMSLeagueSetting | null
  attachment1: Attachment
  attachment2: Attachment
  recipients: Recipient[]
}

const CommunicationsState: CommunicationsState = {
  currentProviderInfo: getNewProviderInfo(),
  messagePreview: getEmptyPreviewMessage(),
  isTollFreeApplicationPending: false,
  statuses: new Array<CommunicationStatusData>(),
  messagePreviewLoading: false,
  leagueSettings: null,
  attachment1: getEmptyAttachment(),
  attachment2: getEmptyAttachment(),
  recipients: [],
}

export enum mutationNames {
  setCurrent = 'setCurrent',
  setPreviewMessage = 'setPreviewMessage',
  setTollFreeApplicationPending = 'setTollFreeApplicationPending',
  setStatuses = 'setStatuses',
  clearStatuses = 'clearStatuses',
  setSelectedGroups = 'setSelectedGroups',
  setAccountNumber = 'setAccountNumber',
  setUpwardLeagueID = 'setUpwardLeagueID',
  setCommunicationType = 'setCommunicationType',
  setToDelimited = 'setToDelimited',
  setTo = 'setTo',
  setMessageBody = 'setMessageBody',
  setMessagePreviewLoading = 'setMessagePreviewLoading',
  setAttachment = 'setAttachment',
  removeAttachment = 'removeAttachment',
  setRecipients = 'setRecipients',
  setTeams = 'setTeams',
  setTypeProgramID = 'setTypeProgramID',
  setPracticeNight = 'setPracticeNight',
  setStartGrade = 'setStartGrade',
  setEndGrade = 'setEndGrade',
  setPaymentStatus = 'setPaymentStatus',
  setEvalStatus = 'setEvalStatus',
  setCoachTeamStatus = 'setCoachTeamStatus',
  setViewAllLeagueContacts = 'setViewAllLeagueContacts',
  setPracticeFacilityIDs = 'setPracticeFacilityIDs',
  setGender = 'setGender',
  setEmailSubject = 'setEmailSubject',
  setLeagueSettings = 'setLeagueSettings',
  setEmailDisplayName = 'setEmailDisplayName',
  setEmailReplyTo = 'setEmailReplyTo',
  clearMessageInformation = 'clearMessageInformation',
}

const mutations = defineMutations<CommunicationsState>()({
  [mutationNames.setCurrent](state, { item }: { item: EmailSMSAccount }) {
    state.currentProviderInfo = item
  },
  [mutationNames.setPreviewMessage](state, { item }: { item: PreviewMessage }) {
    state.messagePreview = item
  },
  [mutationNames.setTollFreeApplicationPending](state, { item }: { item: boolean }) {
    state.isTollFreeApplicationPending = item
  },
  [mutationNames.setStatuses](state, { item }: { item: CommunicationStatusData[] }) {
    state.statuses = item
  },
  [mutationNames.clearStatuses](state) {
    state.statuses = []
  },
  [mutationNames.setSelectedGroups](state, { groups }: { groups: string[] }) {
    state.messagePreview.selectedGroups = groups
  },
  [mutationNames.setAccountNumber](state, { accountNumber }: { accountNumber: string }) {
    state.messagePreview.accountNumber = accountNumber
  },
  [mutationNames.setUpwardLeagueID](state, { upwId }: { upwId: string }) {
    state.messagePreview.upwardLeagueID = upwId
  },
  [mutationNames.setCommunicationType](state, { communicationType }: { communicationType: string }) {
    state.messagePreview.communicationType = communicationType
  },
  [mutationNames.setToDelimited](state, { toDelimited }: { toDelimited: string }) {
    state.messagePreview.toDelimited = toDelimited
  },
  [mutationNames.setTo](state, { to }: { to: string[] }) {
    state.messagePreview.to = to
  },
  [mutationNames.setMessageBody](state, { messageBody }: { messageBody: string }) {
    state.messagePreview.messageBody = messageBody
  },
  [mutationNames.setMessagePreviewLoading](state, { loading }: { loading: boolean }) {
    state.messagePreviewLoading = loading
  },
  //commCriteria mutations
  [mutationNames.setTeams](state, { teams }: { teams: number[] }) {
    state.messagePreview.teams = teams
  },
  [mutationNames.setTypeProgramID](state, { typeProgramID }: { typeProgramID: string }) {
    state.messagePreview.typeProgramID = typeProgramID
  },
  [mutationNames.setPracticeNight](state, { practiceNight }: { practiceNight: string | null }) {
    state.messagePreview.practiceNight = practiceNight
  },
  [mutationNames.setStartGrade](state, { startGrade }: { startGrade: string }) {
    state.messagePreview.startGrade = startGrade
  },
  [mutationNames.setEndGrade](state, { endGrade }: { endGrade: string }) {
    state.messagePreview.endGrade = endGrade
  },
  [mutationNames.setPaymentStatus](state, { paymentStatus }: { paymentStatus: string }) {
    state.messagePreview.paymentStatus = paymentStatus
  },
  [mutationNames.setEvalStatus](state, { evalStatus }: { evalStatus: string }) {
    state.messagePreview.evalStatus = evalStatus
  },
  [mutationNames.setCoachTeamStatus](state, { coachTeamStatus }: { coachTeamStatus: string }) {
    state.messagePreview.coachTeamStatus = coachTeamStatus
  },
  [mutationNames.setViewAllLeagueContacts](
    state,
    { viewAllLeagueContacts }: { viewAllLeagueContacts: boolean }
  ) {
    state.messagePreview.viewAllLeagueContacts = viewAllLeagueContacts
  },
  [mutationNames.setPracticeFacilityIDs](state, { practiceFacilityIDs }: { practiceFacilityIDs: number[] }) {
    state.messagePreview.practiceFacilityIDs = practiceFacilityIDs
  },
  [mutationNames.setGender](state, { gender }: { gender: string | null }) {
    state.messagePreview.gender = gender
  },
  [mutationNames.setEmailSubject](state, { emailSubject }: { emailSubject: string }) {
    state.messagePreview.emailSubject = emailSubject
  },
  [mutationNames.setLeagueSettings](state, { settings }: { settings: EmailSMSLeagueSetting | null }) {
    state.leagueSettings = settings
  },
  [mutationNames.setEmailDisplayName](state, { emailDisplayName }: { emailDisplayName: string }) {
    state.messagePreview.displayName = emailDisplayName
  },
  [mutationNames.setEmailReplyTo](state, { emailReplyTo }: { emailReplyTo: string }) {
    state.messagePreview.replyTo = emailReplyTo
  },
  [mutationNames.setAttachment](
    state,
    { attachment, position }: { attachment: Attachment; position: number }
  ) {
    if (position === 1) {
      state.attachment1 = attachment
    } else if (position === 2) {
      state.attachment2 = attachment
    }
  },
  [mutationNames.removeAttachment](state, { position }: { position: number }) {
    if (position === 1) {
      state.attachment1 = getEmptyAttachment()
    } else if (position === 2) {
      state.attachment2 = getEmptyAttachment()
    }
  },
  [mutationNames.setRecipients](state, { recipients }: { recipients: Recipient[] }) {
    state.recipients = recipients
  },
  [mutationNames.clearMessageInformation](state) {
    state.messagePreview = getEmptyPreviewMessage()
    state.attachment1 = getEmptyAttachment()
    state.attachment2 = getEmptyAttachment()
    state.recipients = []
  },
})

export enum getterNames {
  currentItem = 'currentItem',
  isTollFreeApplicationPending = 'isTollFreeApplicationPending',
  messagePreviewLoading = 'messagePreviewLoading',
  commCriteria = 'commCriteria',
  leagueSettings = 'leagueSettings',
  attachment1 = 'attachment1',
  attachment2 = 'attachment2',
  recipients = 'recipients',
  hasSelectedRecipients = 'hasSelectedRecipients',
}

const getterTree = defineGetters<CommunicationsState>()({
  currentItem: (state) => state.currentProviderInfo,
  messagePreview: (state) => state.messagePreview,
  isTollFreeApplicationPending: (state) => state.isTollFreeApplicationPending,
  statuses: (state) => state.statuses,
  messagePreviewLoading: (state) => state.messagePreviewLoading,
  leagueSettings: (state) => state.leagueSettings,
  attachment1: (state) => state.attachment1,
  attachment2: (state) => state.attachment2,
  recipients: (state) => state.recipients,
  hasSelectedRecipients: (state) => {
    // Check recipients array for any selected recipients
    const hasSelectedInList = state.recipients.some((recipient) => recipient.isSelected)

    // Check messagePreview.to array for any entries
    const hasToRecipients = state.messagePreview.to.length > 0

    // Check messagePreview.toDelimited for any entries
    const hasToDelimited = state.messagePreview.toDelimited?.length > 0

    return hasSelectedInList || hasToRecipients || hasToDelimited
  },
})

export interface LeagueData {
  upwardID: string
  accountNumber: string
}

export enum actionNames {
  retrieveAndSetAsCurrent = 'retrieveAndSetAsCurrent',
  loadFromCache = 'loadFromCache',
  beginCreating = 'beginCreating',
  retrieveMessagePreview = 'retrieveMessagePreview',
  retrieveStatuses = 'retrieveStatuses',
  retrieveRecipientList = 'retrieveRecipientList',
  updateTo = 'updateTo',
  sendMessage = 'sendMessage',
  updateTypeProgramID = 'updateTypeProgramID',
  updateApprovalStatus = 'updateApprovalStatus',
  updateHeadCoachOnly = 'updateHeadCoachOnly',
  fetchLeagueSettings = 'fetchLeagueSettings',
  updateLeagueSettings = 'updateLeagueSettings',
  setRecipientsFromRunningParticipants = 'setRecipientsFromRunningParticipants',
  updateRecipientsAndPreview = 'updateRecipientsAndPreview',
}

const actions = defineActions({
  async [actionNames.retrieveStatuses](
    { commit },
    { accountNumber, upwardLeagueID }: { accountNumber: string; upwardLeagueID: string }
  ): Promise<CommunicationStatusData[]> {
    const data = await communicationsClient.getCommunicationStatuses(accountNumber, upwardLeagueID)
    commit(mutationNames.setStatuses, { item: data })
    return data
  },
  async [actionNames.retrieveAndSetAsCurrent](
    { commit },
    { payload }: { payload: LeagueData }
  ): Promise<EmailSMSAccount | null> {
    try {
      if (payload == null) {
        throw new Error('payload required')
      } else {
        const providerInfo = await communicationsClient.retrieveProviderInfo(
          payload.upwardID,
          payload.accountNumber
        )

        commit(mutationNames.setCurrent, { item: providerInfo })

        return providerInfo
      }
    } catch (error) {
      throw new Error('communications store: unable to retrieve communications provider info')
    }
  },
  async [actionNames.retrieveMessagePreview](
    { commit },
    { previewMessage }: { previewMessage: PreviewMessage }
  ): Promise<PreviewMessage | null> {
    try {
      commit(mutationNames.setMessagePreviewLoading, { loading: true })
      if (previewMessage == null) {
        throw new Error('payload required')
      } else {
        const preview = await communicationsClient.retrieveMessagePreview(previewMessage)

        commit(mutationNames.setPreviewMessage, { item: preview })

        return preview
      }
    } catch (error) {
      throw new Error('communications store: unable to retrieve communications provider info')
    } finally {
      commit(mutationNames.setMessagePreviewLoading, { loading: false })
    }
  },
  async [actionNames.retrieveRecipientList](
    { commit, state },
    { communicationType }: { communicationType: MessageChannelTypes }
  ): Promise<PreviewMessage | null> {
    try {
      // set account number
      if (state.messagePreview.accountNumber == '') {
        commit(mutationNames.setAccountNumber, { accountNumber: state.currentProviderInfo.accountNumber })
      }
      //set league id (could be UPWID or URID)
      const currentLeagueId = store.getters.leagueAbstraction.currentItem.upwardLeagueID
      if (state.messagePreview.upwardLeagueID == '') {
        commit(mutationNames.setUpwardLeagueID, { upwId: currentLeagueId })
      }
      //set communication type  — sms or email
      commit(mutationNames.setCommunicationType, { communicationType })

      //clear to field
      commit(mutationNames.setTo, { to: [] })
      // fetch and the recipient list
      commit(mutationNames.setMessagePreviewLoading, { loading: true })
      const preview = await communicationsClient.retrieveMessagePreview(state.messagePreview)
      commit(mutationNames.setTo, { to: preview?.to ?? [] })
      commit(mutationNames.setMessagePreviewLoading, { loading: false })

      return preview
    } catch (error) {
      throw new Error('communications store: unable to retrieve communications provider info')
    }
  },
  [actionNames.updateTo]({ commit }, { to }: { to: string[] }) {
    commit(mutationNames.setTo, { to })
  },
  async [actionNames.sendMessage](
    { state },
    {
      type,
      sendCommunicationOn,
      minutesToAdd,
    }: { type: 'sendemail' | 'sendsms'; sendCommunicationOn: Date | null; minutesToAdd: number | null }
  ): Promise<void> {
    const emailMessage = CommunicationService.previewMessageToMessage(
      state.messagePreview,
      state.attachment1,
      state.attachment2,
      sendCommunicationOn,
      minutesToAdd,
      type
    )
    await communicationsClient.sendMessage(emailMessage, type)
  },
  async [actionNames.fetchLeagueSettings](
    { commit },
    { upwID, emailSMSAccountID }: { upwID: string; emailSMSAccountID: number }
  ): Promise<EmailSMSLeagueSetting | null> {
    try {
      const settings = await communicationsClient.retrieveLeagueSettings(upwID, emailSMSAccountID)
      commit(mutationNames.setLeagueSettings, { settings })
      return settings
    } catch (error) {
      throw new Error('communications store: unable to retrieve league settings')
    }
  },
  async [actionNames.updateLeagueSettings]({ commit }, { settings }: { settings: EmailSMSLeagueSetting }) {
    try {
      const updatedSettings = await communicationsClient.updateLeagueSettings(settings)
      commit(mutationNames.setLeagueSettings, { settings: updatedSettings ?? null })
      return updatedSettings
    } catch (error) {
      throw new Error('communications store: unable to update league settings')
    }
  },
  async [actionNames.setRecipientsFromRunningParticipants](
    { commit },
    { upwardRunningId }: { upwardRunningId: string }
  ): Promise<Recipient[]> {
    try {
      const result = await upwardRunningClient.retrieveParticipants(upwardRunningId)
      const recipients =
        result.data?.map((participant) => ({
          id: participant.individualID ?? '',
          name: participant.formattedName ?? '',
          email: participant.emailAddress ?? '',
          phone: participant.phoneNumber ?? '',
          isSelected: false,
        })) ?? []

      commit(mutationNames.setRecipients, { recipients })
      return recipients
    } catch (error) {
      throw new Error('communications store: unable to set recipients from participants')
    }
  },
  [actionNames.updateRecipientsAndPreview](
    { commit },
    { recipients, type }: { recipients: Recipient[]; type: string }
  ) {
    commit(mutationNames.setRecipients, { recipients })
    const listOfSelectedEmailsOrPhones = recipients
      .filter((recipient) => recipient.isSelected)
      .map((recipient) => (type === 'sms' ? recipient.phone : recipient.email))
    commit(mutationNames.setTo, { to: listOfSelectedEmailsOrPhones })
  },
})

export const namespace = 'communications'

export const communications = {
  namespaced: true as true,
  state: CommunicationsState,
  getters: getterTree,
  mutations,
  actions,
}
