













































































//import Vue from 'vue'
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { getEmptyLeagueDivisionInfo } from '@/lib/support/models/LeagueDivisionInfo/data'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { useReports } from '@/views/Programs/Divisions/TeamManagement/logic/reports'
import { getEmptyLeagueDivisionExtendedInfo } from '@/lib/support/models/LeagueDivisionExtendedInfo/data'
import { LeagueDivisionExtendedInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionExtendedInfo'

import Accordion from '@/components/Accordion.vue'
import AccordionItem from '@/components/AccordionItem.vue'
import ReportModal from '@/components/ReportModal.vue'
import BrokenCoachLinkReport from '@/views/Programs/Divisions/TeamManagement/vues/reports/BrokenCoachLinkReport.vue'
import CoachOverageReport from '@/views/Programs/Divisions/TeamManagement/vues/reports/CoachOverageReport.vue'
import EvaluationsMissingReport from '@/views/Programs/Divisions/TeamManagement/vues/reports/EvaluationsMissingReport.vue'
import BrokenCarpoolLinkReport from '@/views/Programs/Divisions/TeamManagement/vues/reports/BrokenCarpoolLinkReport.vue'

export default defineComponent({
  components: {
    CoachOverageReport,
    ReportModal,
    BrokenCoachLinkReport,
    EvaluationsMissingReport,
    BrokenCarpoolLinkReport,
    Accordion,
    AccordionItem,
  },
  props: {
    division: {
      type: Object as PropType<LeagueDivisionInfo | null>,
      required: true,
      default: () => getEmptyLeagueDivisionInfo(),
    },
  },
  setup(props) {
    const reports = useReports()
    const seeBrokenCoachLink = ref(false)
    const seeBrokenCarpoolLink = ref(false)
    const seeMissingEvaluations = ref(false)
    const seeCoachLinkStatus = ref(false)
    watch(
      () => props.division,
      () => {
        if (props.division) {
          reports.refresh(
            props.division?.upwardLeagueID ?? '',
            props.division?.typeProgramID ?? '',
            props.division.divisionID
          )
        }
      },
      { immediate: true }
    )

    return {
      summary: computed(
        () =>
          reports.summary?.value ??
          ({ value: getEmptyLeagueDivisionExtendedInfo() } as { value: LeagueDivisionExtendedInfo })
      ),
      seeBrokenCoachLink,
      seeBrokenCarpoolLink,
      seeCoachLinkStatus,
      seeMissingEvaluations,
      reportCoachLinks: reports.reportCoachLinks,
      reportCarpoolLinks: reports.reportCarpoolLinks,
      reportEvaluationsMissing: reports.reportEvaluationsMissing,
      reportCoachOverage: reports.reportCoachOverage,
    }
  },
})
