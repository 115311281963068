import { cloneDeep } from 'lodash'
import { getEmptyXRunningOrderExt } from '@/lib/support/models/GeneratedTypes/xOrders/xRunningOrderExt'
import { xRunningOrderExt } from '@/models/UpwardRunning/Order/xRunningOrderExt'
import {
  xRunningOrderDetail,
  xRunningOrderDetailIndividual,
  xRunningOrderDetailParticipantProduct,
  xRunningOrderDetailAddonProduct,
  getEmptyRunningOrderDetail,
} from '@/models/UpwardRunning/Order/xRunningOrderExt'

export enum UROSStates {
  PRE_STEP,
  REVIEW_PARTICIPANT_DETAIL,
  REVIEW_SHIPPING,
  REVIEW_ORDER,
  CONFIRM_ORDER,
  REVIEW_ADDON_ITEMS,
}

export interface UpwardRunningOrderState {
  template: xRunningOrderExt
  currentStep: UROSStates
  orderStatus: xRunningOrderDetail
  productIndividualPending: string[]
  loading: boolean
  validated: boolean
  pendingAddOnItems: xRunningOrderDetailAddonProduct[]
}

export function newUROS(challenge: string) {
  const rv = {
    template: getEmptyXRunningOrderExt(),
    currentStep: UROSStates.PRE_STEP,
    orderStatus: getEmptyRunningOrderDetail(challenge),
    productIndividualPending: [],
    loading: false,
    validated: false,
    pendingAddOnItems: [] as xRunningOrderDetailAddonProduct[],
  } as UpwardRunningOrderState

  return rv
}

export interface SizeUpdatedPayload {
  upwardChallengeID: string
  challengeID: number
  program: string
  participantID: number
  product: xRunningOrderDetailParticipantProduct
}

/**
 * Replace the product in players in the teams sizing structure.
 * @param teamPlayers
 * @param individualID
 * @param product
 */
export function setProductInParticipants(
  participantsParm: xRunningOrderDetailIndividual[],
  individualID: number,
  product: xRunningOrderDetailParticipantProduct
) {
  const participants = cloneDeep(participantsParm)
  const cI = participants.findIndex((c) => c.individualID == individualID)
  if (cI >= 0) {
    const pI = participants[cI].products.findIndex((p) => p.upwardProductID == product.upwardProductID)
    if (pI >= 0) {
      participants[cI].products.splice(pI, 1, {
        ...participants[cI].products[pI],
        typeSizeID: product.typeSizeID,
      })
    }
  }
  return participants
}
