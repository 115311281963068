import { EmailMessage } from '@/GeneratedTypes/Communication/EmailMessage'

export function getEmptyEmailMessage(): EmailMessage {
  return {
    accountNumber: '',
    upwardLeagueID: '',
    displayName: '',
    replyTo: '',
    emailSubject: '',
    messageBody: '',
    to: [],
    attachments: [],
    sendCommunicationOn: null,
    minutesToAdd: null,
    recipientCriteria: null,
  }
}
